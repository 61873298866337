import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { ProjectRepository } from "@src/services/project.repository";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setSelectedProject } from "@src/components/layout/private-layout/project-slice";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { updateSelectProjectOnParam } from "@src/helpers/utils";

interface IProps {
  projectRepo?: ProjectRepository;
}
//TODO should test for url params in test
export const SelectProject: React.FC<IProps> = ({ projectRepo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const [searchParams] = useSearchParams();
  const paramsSelectedProject = searchParams.get("selectedProject");
  const reduxSelectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject.name
  );
  const [repo, setRepo] = useState<ProjectRepository>(
    projectRepo || new ProjectRepository()
  );

  const setDefaultToFirstProject = async () => {
    const { data: projects } = await repo.getAll({
      page_size: 1,
    });
    if (projects.length > 0) {
      dispatch(setSelectedProject(projects[0]));
      const newSearch = updateSelectProjectOnParam(
        projects[0].name,
        window.location.search
      );
      navigate(`${url}?${newSearch}`);
    } else {
      if (url != EPRIVATEROUTE.PROJECTS_CREATE) {
        navigate(EPRIVATEROUTE.EMPTY_PROJECT_LIST);
      }
    }
  };
  const setDefaultToUrlParam = async (projectName: string) => {
    if (reduxSelectedProject == projectName) return;
    const { data: projects } = await repo.getAll({
      name: projectName,
      page_size: 1,
    });
    if (projects.length > 0) {
      dispatch(setSelectedProject(projects[0]));
      const newSearch = updateSelectProjectOnParam(
        projects[0].name,
        window.location.search
      );

      navigate(`${url}?${newSearch}`);
    } else {
      setDefaultToFirstProject();
    }
  };
  const setDefaultProject = () => {
    if (paramsSelectedProject) setDefaultToUrlParam(paramsSelectedProject);
    else setDefaultToFirstProject();
  };
  useEffect(() => {
    setRepo(projectRepo || repo);
  }, [projectRepo != null]);
  useEffect(() => {
    setDefaultProject();
  }, []);
  return null;
};
