import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  EntityTable,
  IColumn,
} from "@src/components/entity_table/entity_table";
import { useTranslation } from "react-i18next";
import { SearchBox } from "@src/components/layout/private-layout/searchBox/searchBox";
import { IEntityModel } from "@src/models/entity-model.model";
import { IResult } from "@src/services/repository";
import { colDef } from "@src/helpers/utils";
import { Noop } from "react-hook-form";

interface IProps<T extends IEntityModel> {
  onBlur?: Noop;
  fieldError?: string;
  columns: Array<IColumn<T>>;
  renderDisplayValue: (data: T) => string;
  label: string;
  onChange: (data: T) => void;
  fetchData: (
    filter: string
  ) => Promise<IResult<Array<Omit<T, "hasSelectIcon">>>>;
  selectedValue: T | null;
}
export function TableSelector<T extends IEntityModel>(props: IProps<T>) {
  const {
    onBlur,
    fieldError,
    columns,
    renderDisplayValue,
    fetchData,
    label,
    onChange,
    selectedValue,
  } = props;
  const { t } = useTranslation();

  const [query, setQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [tableRows, setTableRows] = useState<Array<T>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  type TWithoutSelectIcon = Omit<T, "hasSelectIcon">;

  const columnsWithSelectButton: Array<IColumn<T>> = [
    ...columns,
    colDef<T>("id", "", (_, y) => (
      <Button
        onClick={() => {
          onChange(y);
          setShowModal(false);
        }}
      >
        {t("select")}
      </Button>
    )),
  ];
  const fetcherTableList = async () => {
    setLoading(true);
    setError(false);
    try {
      const { data } = await fetchData(query);
      const newList: Array<T> = data.map((i: TWithoutSelectIcon) => {
        return {
          ...(i as T),
          hasSelectIcon: i.id === selectedValue?.id,
        };
      });
      setTableRows(newList);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    showModal && fetcherTableList();
  }, [showModal, query, refresh]);
  return (
    <>
      <TextField
        onBlur={onBlur}
        error={!!fieldError && !showModal}
        aria-invalid={!!fieldError && !showModal}
        helperText={!showModal ? fieldError : ""}
        data-testid={`${label}_textfield`}
        name={label}
        onClick={() => setShowModal(true)}
        label={label}
        id={label}
        placeholder={label}
        type="text"
        variant="outlined"
        fullWidth
        value={renderDisplayValue(selectedValue!)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
      />

      <Dialog
        fullWidth={true}
        open={showModal}
        onClose={() => {
          setQuery("");
          setShowModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Container>
          <Stack
            marginTop={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={4}
          >
            <Typography variant="h5">{t(`select ${label}`)}</Typography>
            <Button
              disabled={loading}
              data-testid="refreshPage"
              variant="text"
              onClick={() => setRefresh(!refresh)}
              startIcon={<RefreshIcon />}
            >
              {t("refresh")}
            </Button>
          </Stack>
          <Stack>
            <SearchBox
              customPlaceHolder={`${t("search")} ${label}`}
              onChange={setQuery}
            />
          </Stack>
        </Container>
        <DialogContent>
          <EntityTable<T>
            bulkeSelectedMessage=""
            error={error}
            loading={loading}
            tableColumns={columnsWithSelectButton}
            dataList={tableRows}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setQuery("");
              setShowModal(false);
            }}
            autoFocus
            data-testid="confirm-agree"
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
