import { IRoute } from "@src/components/type/route-type";
import { NavItem } from "./navigation-item";
import { matchPath, useLocation } from "react-router-dom";
import { Box, List } from "@mui/material";
import { Fragment } from "react";
interface IProps {
  navConfig: IRoute[];
}

export function NavSection({ navConfig }: IProps) {
  const { pathname } = useLocation();

  const match = (path: string) => {
    return path ? !!matchPath({ path, end: false }, pathname) : false;
  };

  return (
    <Box>
      <List
        aria-labelledby="navigation-sidebar"
        className="listWrapper"
        data-testid="listWrapper"
        disablePadding
      >
        {navConfig.map((item: IRoute) => {
          return (
            <Fragment key={item.keyIndex}>
              {item.inMenu && <NavItem item={item} active={match} />}
            </Fragment>
          );
        })}
      </List>
    </Box>
  );
}
