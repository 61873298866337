import { TFunction } from "i18next";
import * as Yup from "yup";
import Ajv from "ajv";
const ajv = new Ajv({ allErrors: true });
function testJSON(text: string) {
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
}

export const Validation = (t: TFunction) => {
  return Yup.object().shape({
    validator_param_values: Yup.string()
      .required(`${t("error__validator param values is required")}`)
      .test(
        "is-valid-json",
        `${t("error__validator param values should be valid JSON")}`,
        function (value) {
          if (!value || !testJSON(value)) {
            return this.createError({
              message: `${t(
                "error__validator param values should be valid JSON"
              )}`,
            });
          }
          try {
            const object = JSON.parse(value);
            const valid = ajv.validateSchema(object);
            if (object && !valid) {
              return this.createError({
                message: ajv.errorsText(),
              });
            }
          } catch (error) {
            return this.createError({
              message: ajv.errorsText(),
            });
          }
          return true;
        }
      ),

    name: Yup.string()
      .required(`${t("error__name is required")}`)
      .matches(
        /^\S+$/,
        `${t("error__the value should not have any white space")}`
      ),
    title: Yup.string().required(`${t("error__title is required")}`),
    validator_id: Yup.string().required(`${t("error__validator is required")}`),
  });
};
