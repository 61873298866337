import { Button, CardContent, Grid, Stack, TextField } from "@mui/material";
import * as Yup from "yup";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { IProjectFilters } from "@src/models/project.model";

interface IProps {
  handelFormData: (data: IProjectFilters) => void;
  resetFilter: () => void;
  defaultValue: IProjectFilters;
}
export const Filter: React.FC<IProps> = ({
  handelFormData,
  resetFilter,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    creator: Yup.string()
      .email(`${t("error__email must be a valid email address")}`)
      .nullable(),
  });
  const methods = useForm<IProjectFilters>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: defaultValue,
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  const isValid = Object.keys(errors).length == 0;
  return (
    <Stack
      className="filter-form-wrapper"
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <CardContent>
        <form onSubmit={handleSubmit(handelFormData)}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            direction="column"
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <TextField
                  label={t("name")}
                  id="name"
                  placeholder={t("name") || ""}
                  type="text"
                  variant="outlined"
                  fullWidth
                  {...register("name")}
                  error={!!errors?.name}
                  aria-invalid={errors.name ? true : false}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t("page__project__creator")}
                  id="creator"
                  placeholder="example@gmail.com"
                  variant="outlined"
                  fullWidth
                  {...register("creator")}
                  error={!!errors?.creator}
                  aria-invalid={errors.creator ? true : false}
                  helperText={errors?.creator?.message}
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="end"
                alignItems="center"
              >
                <Button
                  className="button-margin-submit"
                  data-testid="submit_button"
                  disabled={!isValid}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {t("filter")}
                </Button>

                <Button
                  className="button-margin"
                  data-testid="reset_button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    reset({ name: null, creator: null });
                    resetFilter();
                  }}
                >
                  {t("reset")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Stack>
  );
};
