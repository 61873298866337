import { Button, CardContent, Grid, Stack, TextField } from "@mui/material";
import * as Yup from "yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { IValidatorProviderFilter } from "@src/models/validator-provider.model";
import { backgroundTaskStatusOption } from "@src/containers/private/validator-provider/constant/status-select-option";

import { CustomSelect } from "@src/components/form/custom-select";

interface IProps {
  handleFormData: (data: IValidatorProviderFilter) => void;
  resetFilter: () => void;
  defaultValue: IValidatorProviderFilter;
}
export const Filter: React.FC<IProps> = ({
  handleFormData,
  resetFilter,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    endpoint: Yup.string()
      .url(`${t("error__endpoint must be a valid url address")}`)
      .nullable(),
  });
  const methods = useForm<IValidatorProviderFilter>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: defaultValue,
  });
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  const isValid = Object.keys(errors).length == 0;
  return (
    <Stack
      className="filter-form-wrapper"
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <CardContent>
        <form onSubmit={handleSubmit(handleFormData)}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            direction="column"
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="background_task_status"
                  render={({ field: { onChange, value, name } }) => (
                    <CustomSelect
                      options={backgroundTaskStatusOption}
                      onChange={onChange}
                      name={name}
                      value={value || ""}
                      label={t("page__validatorProvider__status")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t("name")}
                  id="validatorProviderName"
                  placeholder={t("name") || ""}
                  type="text"
                  variant="outlined"
                  fullWidth
                  {...register("name")}
                  error={!!errors?.name}
                  aria-invalid={errors.name ? true : false}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t("title")}
                  id="validatorProviderTitle"
                  placeholder={t("title") || ""}
                  type="text"
                  variant="outlined"
                  fullWidth
                  {...register("title")}
                  error={!!errors?.title}
                  aria-invalid={errors.title ? true : false}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t("page__validatorProvider__endpoint")}
                  id="validatorProviderEndpoint"
                  placeholder="https://validator.detailist.void-star.co/"
                  variant="outlined"
                  fullWidth
                  {...register("endpoint")}
                  error={!!errors?.endpoint}
                  aria-invalid={errors.endpoint ? true : false}
                  helperText={errors?.endpoint?.message}
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="end"
                alignItems="center"
              >
                <Button
                  className="button-margin-submit"
                  data-testid="submit_button"
                  disabled={!isValid}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {t("filter")}
                </Button>

                <Button
                  className="button-margin"
                  data-testid="reset_button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    reset({
                      name: null,
                      title: null,
                      endpoint: null,
                      background_task_status: null,
                    });
                    resetFilter();
                  }}
                >
                  {t("reset")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Stack>
  );
};
