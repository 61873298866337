import { IServiceDefinition, IServiceFilter } from "@src/models/service.model";
import { Repository } from "@src/services/repository";

export class ServiceRepository extends Repository<
  IServiceDefinition,
  IServiceDefinition,
  IServiceDefinition,
  IServiceDefinition,
  IServiceFilter
> {
  constructor() {
    super("/v1.0/service_definition");
  }
}
