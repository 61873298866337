import {
  colDef,
  removeNullValue,
  updateUrlParamsWithQuery,
} from "@src/helpers/utils";
import { IValidator, IValidatorFilter } from "@src/models/validator.model";
import {
  NESTED_FIELD,
  ValidatorRepository,
} from "@src/services/validator.repository";
import { AxiosError } from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { HeaderList } from "@src/containers/private/validator/header";
import { Box, Grid, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  EntityTable,
  IColumn,
  ITableAction,
} from "@src/components/entity_table/entity_table";
import { Filter } from "@src/containers/private/validator/filter";
import { Footer } from "@src/components/list-footer/footer";
import { ITableBulkAction } from "@src/components/type/tabel-bulk-action-type";
import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { LIST_PAGE_SIZE } from "@src/containers/private/constant/list-page-size";
import { EMODAL_ACTION } from "@src/constants/enum/confirm-modal-action";
import { ConfirmModal } from "@src/components/confirm_modal";
import { EntityNotFoundException } from "@src/services/repository";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useNavContext } from "@src/app/navigation-context";
export interface IProps {
  validatorRepo?: ValidatorRepository;
}

export const Validators: React.FC<IProps> = ({ validatorRepo }) => {
  const { t } = useTranslation();
  const navContext = useNavContext();

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const url = location.pathname;
  const name = searchParams.get("name");
  const title = searchParams.get("title");
  const category = searchParams.get("category");
  const since = searchParams.get("since");
  const before = searchParams.get("before");
  const page_size = Number(searchParams.get("page_size"));
  const validator_provider_id = searchParams.get("validator_provider_id");
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const [selectedDeleteData, setSelectedDeleteData] = useState<IValidator>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [bulkSelected, setBulkSelected] = useState<Array<IValidator>>([]);
  const [validators, setValidators] = useState<Array<IValidator>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [query, setQuery] = useState<IValidatorFilter>({
    name: name,
    validator_provider_id: validator_provider_id,
    category: category,
    title: title,
    since: since,
    before: before,
    page_size: page_size || LIST_PAGE_SIZE,
  });
  const [showFilterForm, setShowFilterForm] = useState<boolean>(
    query.name !== null ||
      query.title !== null ||
      query.validator_provider_id !== null ||
      query.category !== null
  );
  const [repo, setRepo] = useState<ValidatorRepository>(
    validatorRepo || new ValidatorRepository()
  );

  const bulkAction: Array<ITableBulkAction> = [
    {
      icon: <DeleteIcon />,
      title: t("delete"),
      isDisabled: false,
      callBack: () => {
        return;
      },
    },
  ];
  const actionList: Array<ITableAction<IValidator>> = [
    {
      icon: <DeleteIcon />,
      title: t("delete"),
      action: (data: IValidator) => {
        setShowDeleteModal(true);
        setSelectedDeleteData(data);
      },
    },
    {
      icon: <ModeEditIcon />,
      title: t("edit"),
      action: (data: IValidator) => {
        navContext(`${EPRIVATEROUTE.VALIDATORS}/${data.id}`);
      },
    },
  ];
  const deleteValidator = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await repo.delete(selectedDeleteData!);
      if (res) {
        toast.success(
          t("message__your request has been successfully completed")
        );
        setRefresh(!refresh);
      }
    } catch (error) {
      if (error instanceof EntityNotFoundException) {
        toast.success(
          t("message__your request has been successfully completed")
        );
        return;
      }
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };
  const getActionModal = (data: EMODAL_ACTION) => {
    switch (data) {
      case EMODAL_ACTION.YES:
        deleteValidator();
        break;
      default:
        setShowDeleteModal(false);
    }
  };
  const columns: IColumn<IValidator>[] = [
    colDef<IValidator>("name", t("name")),
    colDef<IValidator>("title", t("title")),
    colDef<IValidator>("category", t("page__validator__category")),
    colDef<IValidator>(
      "validator_provider_id",
      t("page__validator__validator_provider"),
      (_, y) => (
        <Link
          to={`${EPRIVATEROUTE.VALIDATOR_PROVIDER_SHOW_}/${y.validator_provider_id}`}
        >
          {y.validator_provider_detail?.name}
        </Link>
      )
    ),
  ];
  useEffect(() => {
    setRepo(validatorRepo || repo);
  }, [validatorRepo != null]);

  const applyQuery = async () => {
    setLoading(true);
    setError(false);
    const validQuery = removeNullValue(query);
    try {
      const { data: validators } = await repo.getAll({
        ...validQuery,
        project_id: selectedProject.id,
        nested_fields: [NESTED_FIELD.VALIDATOR_PROVIDER],
      });
      setValidators(validators);
    } catch (error) {
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
      if (
        error instanceof AxiosError &&
        (error.message === "Network Error" ||
          error.message === "Request failed with status code 504" ||
          error.message === "Request failed with status code 500")
      ) {
        setError(true);
      }
    } finally {
      setLoading(false);
      const urlParams = new URLSearchParams(location.search);
      const newUrl = updateUrlParamsWithQuery<IValidatorFilter>(
        query,
        urlParams,
        url
      );
      if (location.pathname + location.search !== newUrl) {
        navContext(newUrl);
      }
    }
  };

  useEffect(() => {
    applyQuery();
  }, [query, repo, refresh]);
  return (
    <Fragment>
      <Helmet>
        <title>{t("page__validator__validators")}</title>
      </Helmet>
      <ToastContainer />
      <div className="validator-container" data-testid="validator-container">
        <HeaderList
          loading={loading}
          handleRefresh={() => setRefresh(!refresh)}
        />
        <Box
          className="filter-table-content"
          data-testid="filter-table-content"
        >
          <Grid borderBottom={1} borderColor={"#e0e0e0"}>
            <Grid item className="filter-icon">
              {showFilterForm ? (
                <ArrowDropDownIcon
                  onClick={() => setShowFilterForm(!showFilterForm)}
                />
              ) : (
                <ArrowDropUpIcon
                  onClick={() => setShowFilterForm(!showFilterForm)}
                />
              )}
              <Typography>{t("filter")}</Typography>
            </Grid>
            <Grid item>
              {showFilterForm && (
                <Filter
                  defaultValue={{ ...query }}
                  resetFilter={() => {
                    setQuery({
                      page_size: LIST_PAGE_SIZE,
                      name: null,
                      title: null,
                      validator_provider_id: null,
                      category: null,
                      since: null,
                      before: null,
                    });
                  }}
                  handleFormData={(newQuery: IValidatorFilter) => {
                    setQuery({ ...query, ...newQuery });
                  }}
                />
              )}
            </Grid>
          </Grid>
          <EntityTable<IValidator>
            bulkeSelectedMessage={
              bulkSelected.length > 1
                ? t("page__validator__validatorsSelected", {
                    num: bulkSelected.length,
                  })
                : t("page__validator__validatorSelected", {
                    num: bulkSelected.length,
                  })
            }
            selectedItems={bulkSelected}
            onSelectionChange={setBulkSelected}
            showCheckbox={true}
            bulkActions={bulkAction}
            error={error}
            loading={loading}
            tableColumns={columns}
            dataList={validators}
            actions={actionList}
          />
          {error ? (
            <></>
          ) : (
            <Footer<IValidatorFilter>
              query={query}
              loading={loading}
              nextState={repo.getPaginationState().next || ""}
              prevState={repo.getPaginationState().prev || ""}
              handleChange={(query: IValidatorFilter) => setQuery(query)}
            />
          )}
        </Box>
      </div>
      <ConfirmModal
        show={showDeleteModal}
        title={t("delete name", {
          name: selectedDeleteData?.name,
        })}
        text={t("are you sure to delete", {
          name: selectedDeleteData?.name,
        })}
        callBack={getActionModal}
        yes={"delete"}
        no={"cancel"}
      />
    </Fragment>
  );
};
