import { Box, CircularProgress } from "@mui/material";
import React, { Suspense } from "react";
import { OidcSecure, useOidcIdToken } from "@axa-fr/react-oidc";
import { Helmet } from "react-helmet";
import { PublicLayout } from "@src/components/layout/public-layout";
import { publicRoutes } from "@app/public-route";
import { useRoutes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PrivateLayout } from "@src/components/layout/private-layout/private-layout";
import { privateRoutes } from "@app/private-routes";
import { setToken } from "@src/app/token-slice";
import { useAppDispatch } from "@src/redux/hooks";
import { useToken } from "@src/helpers/hook/useToken";
import { ProjectRepository } from "@src/services/project.repository";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { NavContextProvider } from "@app/navigation-context";
import { SelectProject } from "@app/set-select-project";

interface IProps {
  projectRepo?: ProjectRepository;
}
export const App: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { idToken } = useOidcIdToken();
  const dispatch = useAppDispatch();
  if (idToken && idToken !== useToken()) dispatch(setToken(idToken));
  const routes = useRoutes([
    {
      path: "/",
      element: <PublicLayout />,
      children: [...publicRoutes],
    },
    {
      path: EPRIVATEROUTE.DASHBOARD,
      element: (
        <OidcSecure>
          <PrivateLayout />
        </OidcSecure>
      ),
      children: [...privateRoutes],
    },
    {
      path: "*",
      element: <div>not found</div>,
    },
  ]);
  return (
    <>
      <Helmet>
        <title>{t("detailist")}</title>
      </Helmet>
      <NavContextProvider>
        <SelectProject />

        <div className="appWrapper">
          <Suspense
            fallback={
              <>
                <Box className="loadingBox">
                  <CircularProgress />
                </Box>
              </>
            }
          >
            <div className="">{routes}</div>
          </Suspense>
        </div>
      </NavContextProvider>
    </>
  );
};
