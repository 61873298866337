import { Button, CardContent, Grid, Stack, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import * as Yup from "yup";
import React, { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { IValidatorFilter } from "@src/models/validator.model";
import { TableSelector } from "@src/components/form/Table-selector";
import { IValidatorProvider } from "@src/models/validator-provider.model";
import { ValidatorProviderRepository } from "@src/services/validator-provider.repository";
import { colDef, removeNullValue } from "@src/helpers/utils";
import { IColumn } from "@src/components/entity_table/entity_table";
import { IReduxState } from "@src/redux/root-reducer";
import { useAppSelector } from "@src/redux/hooks";
import { ToastContainer, toast } from "react-toastify";
import { IValidatorProviderWithSelection } from "@src/containers/private/validator/model/validator-provider-with-selection.model";
import { SELECT_LIST_PAGE_SIZE } from "@src/containers/private/constant/table-selector-page-size";

interface IProps {
  validatorProviderRepo?: ValidatorProviderRepository;
  handleFormData: (data: IValidatorFilter) => void;
  resetFilter: () => void;
  defaultValue: IValidatorFilter;
}

export const Filter: React.FC<IProps> = ({
  validatorProviderRepo,
  handleFormData,
  resetFilter,
  defaultValue,
}) => {
  const schema = Yup.object().shape({});
  const methods = useForm<IValidatorFilter>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: defaultValue,
  });
  const {
    register,
    getValues,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  const { t } = useTranslation();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const [repo, setRepo] = useState<ValidatorProviderRepository>(
    validatorProviderRepo || new ValidatorProviderRepository()
  );
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const fetchData = (filter: string) => {
    const newQuery = removeNullValue({
      name: filter,
      page_size: SELECT_LIST_PAGE_SIZE,
      project_id: selectedProject.id,
    });
    return repo.getAll(newQuery);
  };
  const initialSelectValue = {
    project_id: selectedProject.id,
    name: "",
    title: "",
    endpoint: "",
    id: getValues("validator_provider_id") || "",
    hasSelectIcon: true,
  };

  const [selectedValue, setSelectedValue] =
    useState<IValidatorProviderWithSelection>(initialSelectValue);
  const columns: IColumn<IValidatorProviderWithSelection>[] = [
    colDef<IValidatorProviderWithSelection>("hasSelectIcon", "", (_, y) =>
      y.hasSelectIcon ? <CheckIcon className="check-icon" /> : null
    ),
    colDef<IValidatorProviderWithSelection>("name", t("name")),
    colDef<IValidatorProviderWithSelection>("title", t("title")),
  ];

  const validator_provider_id = defaultValue.validator_provider_id;
  const getValidatorProviderById = async (id: string) => {
    setFetchLoading(true);
    try {
      const { data: validatorPro } = await repo.getById(id);
      validatorPro &&
        setSelectedValue({
          ...validatorPro,
          id: validator_provider_id!,
          hasSelectIcon: true,
        });
    } catch (errors) {
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    } finally {
      setFetchLoading(false);
    }
  };
  useEffect(() => {
    if (validator_provider_id && selectedValue.name === "") {
      getValidatorProviderById(validator_provider_id);
    }
  }, [validator_provider_id]);

  useEffect(() => {
    setRepo(validatorProviderRepo || repo);
  }, [validatorProviderRepo != null]);

  const isValid = Object.keys(errors).length == 0;
  return (
    <Fragment>
      <ToastContainer />
      <Stack
        data-testid="validator-filter-container"
        className="filter-form-wrapper"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <CardContent>
          <form onSubmit={handleSubmit(handleFormData)}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={2}
              direction="column"
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="validator_provider_id"
                    render={({ field: { onChange } }) => (
                      <TableSelector<IValidatorProviderWithSelection>
                        renderDisplayValue={(value: IValidatorProvider) => {
                          return value.name || value.id;
                        }}
                        fetchData={(filter: string) => fetchData(filter)}
                        columns={columns}
                        onChange={(data: IValidatorProviderWithSelection) => {
                          onChange(data.id);
                          setSelectedValue(data);
                        }}
                        selectedValue={selectedValue}
                        label={t("page__validatorProvider__validatorProvider")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t("name")}
                    id="validatorName"
                    placeholder={t("name")!}
                    type="text"
                    variant="outlined"
                    fullWidth
                    {...register("name")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t("title")}
                    id="validatorTitle"
                    placeholder={t("title")!}
                    type="text"
                    variant="outlined"
                    fullWidth
                    {...register("title")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t("page__validator__category")}
                    id="validatorCategory"
                    placeholder={t("page__validator__category")!}
                    variant="outlined"
                    fullWidth
                    {...register("category")}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="end"
                  alignItems="center"
                >
                  <Button
                    className="button-margin-submit"
                    data-testid="submit_button"
                    disabled={!isValid || fetchLoading}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {t("filter")}
                  </Button>

                  <Button
                    className="button-margin"
                    data-testid="reset_button"
                    variant="contained"
                    disabled={!isValid || fetchLoading}
                    color="primary"
                    onClick={() => {
                      reset({
                        name: null,
                        title: null,
                        category: null,
                        validator_provider_id: null,
                      });
                      setSelectedValue({ ...initialSelectValue, id: "" });
                      resetFilter();
                    }}
                  >
                    {t("reset")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Stack>
    </Fragment>
  );
};
