import {
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Stack,
  Divider,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import HelpIcon from "@mui/icons-material/Help";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ConflictException,
  EntityNotFoundException,
  IEntityFieldError,
  InvalidEntityFields,
} from "@src/services/repository";
import { Loading } from "@src/components/loading";
import { FORM_TOAST_TIMEOUT } from "@src/constants/form";
import { convertArrayToObject, delayCallback } from "@src/helpers/utils";
import { Validation } from "@src/containers/private/validator-provider/validation";
import { TagChipList } from "@src/components/form/tag-chip-list";
import { ValidatorProviderRepository } from "@src/services/validator-provider.repository";
import {
  IValidatorProvider,
  IValidatorProviderImport,
} from "@src/models/validator-provider.model";
import { IReduxState } from "@src/redux/root-reducer";
import { useAppSelector } from "@src/redux/hooks";
import { useNavContext } from "@src/app/navigation-context";
import { IValidatorProviderFormData } from "./model/form.model";

export interface IProps {
  validatorProviderRepo?: ValidatorProviderRepository;
}
export const ImportValidatorProvider: React.FC<IProps> = ({
  validatorProviderRepo,
}) => {
  const { t } = useTranslation();
  const navContext = useNavContext();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const params = useParams();
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [fetchValidatorProvider, setFetchValidatorProvider] =
    useState<IValidatorProvider>({
      id: "",
      background_task_status: null,
      project_id: "",
      description: null,
      endpoint: "https://example.co/",
      name: "",
      title: "",
      tags: [],
    });

  const [loading, setLoading] = useState<boolean>(false);
  const [repo, setRepo] = useState<ValidatorProviderRepository>(
    validatorProviderRepo || new ValidatorProviderRepository()
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const toastAndReturnToList = (
    toastType: "error" | "success",
    message: string,
    params?: Record<string, string>
  ) => {
    if (toastType == "success") {
      toast.success(t(message, params!));
    } else {
      toast.error(t(message, params!));
    }
    delayCallback(
      () => navContext(EPRIVATEROUTE.VALIDATOR_PROVIDERS),
      FORM_TOAST_TIMEOUT
    );
  };
  const methods = useForm<IValidatorProviderFormData>({
    resolver: yupResolver(Validation(t)),
    mode: "onBlur",
    defaultValues: {
      description: null,
      tags: [],
    },
  });

  const {
    register,
    setValue,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = methods;
  const {
    fields: labels,
    append: append_labels,
    remove: remove_labels,
  } = useFieldArray({
    control: methods.control,
    name: "labels",
  });
  const getValidatorProviderById = async (id: string) => {
    setFetchLoading(true);
    try {
      const { data: validatorPro } = await repo.getById(id);
      setFetchValidatorProvider(validatorPro);
      setValue("name", validatorPro.name);
      setValue("title", validatorPro.title);
      setValue("description", validatorPro.description);
      setValue("endpoint", validatorPro?.endpoint);
      setValue("tags", validatorPro.tags);
      const labelsObject = validatorPro.labels || {};

      const convertedLabelsArray = Object.entries(labelsObject).map(
        ([key, value]) => ({ key, value })
      );
      setValue("labels", convertedLabelsArray);
    } catch (errors) {
      if (errors instanceof EntityNotFoundException) {
        toastAndReturnToList(
          "error",
          "message__validator provider is not found",
          {
            id: fetchValidatorProvider.id,
          }
        );
        return;
      } else {
        toastAndReturnToList(
          "error",
          "message__an unexpected error happened, please try again after awhile"
        );
      }
    } finally {
      setFetchLoading(false);
    }
  };
  useEffect(() => {
    setRepo(validatorProviderRepo || repo);
  }, [validatorProviderRepo != null]);
  useEffect(() => {
    if (params.validator_provider_id) {
      setEditMode(true);
      getValidatorProviderById(params.validator_provider_id);
    }
  }, [params.validator_provider_id]);
  const isValid = Object.keys(errors).length == 0;
  const importValidatorProvider = async (
    formValues: IValidatorProviderImport
  ) => {
    setLoading(true);
    const formData = { ...formValues, project_id: selectedProject.id };
    try {
      const res = await repo.import(formData);
      toastAndReturnToList(
        "success",
        "page__validatorProvider__you successfully created your validator provider",
        {
          name: res.data.name || "__",
        }
      );
    } catch (errors) {
      if (!navigator.onLine) {
        toast.error(t("error__network_error"));
        return;
      }
      if (errors instanceof InvalidEntityFields) {
        errors.detail.map(
          (item: IEntityFieldError<IValidatorProviderImport>) => {
            if (item.loc[0] == "body") {
              setError(item.loc[1], {
                message: item.msg,
              });
            }
          }
        );
        return;
      }
      if (errors instanceof ConflictException) {
        setError("name", {
          message: `${t(
            "error__validator provider with this name exists, please choose another name"
          )}`,
        });
        return;
      }
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    } finally {
      setLoading(false);
    }
  };
  const editValidatorProvider = async (
    formValues: IValidatorProviderImport
  ) => {
    setLoading(true);
    const newData = { ...fetchValidatorProvider, ...formValues };
    try {
      const res = await repo.update(newData);
      toastAndReturnToList(
        "success",
        "page__validatorProvider__you successfully update your validator provider",
        {
          name: res.data.name || "_",
        }
      );
    } catch (errors) {
      if (!navigator.onLine) {
        toast.error(t("error__network_error"));
        return;
      }
      if (errors instanceof InvalidEntityFields) {
        errors.detail.map((item: IEntityFieldError<IValidatorProvider>) => {
          if (item.loc[0] == "body") {
            setError(item.loc[1] as keyof IValidatorProviderImport, {
              message: item.msg,
            });
          }
        });
        return;
      }
      if (errors instanceof ConflictException) {
        setError("name", {
          message: `${t(
            "error__validator provider with this name exist,please choose another name"
          )}`,
        });
        return;
      }
      if (errors instanceof EntityNotFoundException) {
        toastAndReturnToList(
          "error",
          "message__validator provider is not found",
          {
            id: fetchValidatorProvider.id,
          }
        );
        return;
      } else {
        toast.error(
          t(
            "message__an unexpected error happened, please try again after awhile"
          )
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const onSubmit = (formValues: IValidatorProviderFormData) => {
    const labelsArray = formValues.labels || [];
    const convertedLabel = convertArrayToObject(labelsArray);
    const formData = { ...formValues, labels: convertedLabel };
    if (editMode) {
      editValidatorProvider(formData);
    } else {
      importValidatorProvider(formData);
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>{t("page__validatorProvider__create")}</title>
      </Helmet>
      <ToastContainer autoClose={FORM_TOAST_TIMEOUT} />
      {fetchLoading ? (
        <Loading />
      ) : (
        <div className="create-validator-provider-wrapper">
          <Grid
            container
            spacing={3}
            data-testid="create-validator-provider-wrapper"
          >
            <Grid item xs={12} md={6}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} rowSpacing={3} direction="column">
                  <Grid item>
                    <TextField
                      label={t("name")}
                      id="name-validatorPro"
                      placeholder={t("name")!}
                      type="text"
                      variant="outlined"
                      fullWidth
                      {...register("name")}
                      error={!!errors?.name}
                      aria-invalid={errors.name ? true : false}
                      helperText={errors?.name?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Link to="">
                              <IconButton color="info">
                                <HelpIcon />
                              </IconButton>
                            </Link>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t("title")}
                      id="title-validatorPro"
                      placeholder={t("title")!}
                      type="text"
                      variant="outlined"
                      fullWidth
                      {...register("title")}
                      error={!!errors?.title}
                      aria-invalid={errors.title ? true : false}
                      helperText={errors?.title?.message}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      multiline
                      label={t("description")}
                      id="description-validatorPro"
                      variant="outlined"
                      type="text"
                      fullWidth
                      rows={3}
                      {...register("description")}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      label={t("page__validatorProvider__endpoint")}
                      id="endpoint-validatorPro"
                      placeholder={t("page__validatorProvider__endpoint")!}
                      type="text"
                      variant="outlined"
                      fullWidth
                      {...register("endpoint")}
                      error={!!errors?.endpoint}
                      aria-invalid={errors.endpoint ? true : false}
                      helperText={errors?.endpoint?.message}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      control={control}
                      name="tags"
                      render={({ field: { onChange, value, name } }) => (
                        <TagChipList
                          onChange={onChange}
                          name={name}
                          tags={value!}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item className="label-wrapper">
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Typography>{t("labels")}</Typography>
                      <Link to="">
                        <IconButton color="info">
                          <HelpIcon />
                        </IconButton>
                      </Link>
                    </Stack>
                    {labels.map((field, index) => {
                      return (
                        <Grid
                          key={field.id}
                          container
                          spacing={2}
                          alignItems={"center"}
                          className="label-input"
                        >
                          <Grid item xs={10} md={5}>
                            <TextField
                              error={!!errors?.labels?.[index]?.key}
                              aria-invalid={
                                errors?.labels?.[index]?.key ? true : false
                              }
                              helperText={
                                errors?.labels?.[index]?.key?.message && (
                                  <span className="label-error">
                                    {errors?.labels?.[index]?.key?.message}
                                  </span>
                                )
                              }
                              fullWidth
                              {...register(`labels.${index}.key`)}
                              type="text"
                              label={t("page__validatorProvider__key")}
                            />
                          </Grid>
                          <Grid item xs={10} md={5}>
                            <TextField
                              error={!!errors?.labels?.[index]?.value}
                              aria-invalid={
                                errors?.labels?.[index]?.value ? true : false
                              }
                              helperText={
                                errors?.labels?.[index]?.value?.message && (
                                  <span className="label-error">
                                    {errors?.labels?.[index]?.value?.message}
                                  </span>
                                )
                              }
                              fullWidth
                              {...register(`labels.${index}.value`)}
                              type="text"
                              label={t("page__validatorProvider__value")}
                            />
                          </Grid>
                          <Grid item xs={2} md={2}>
                            <IconButton
                              color="inherit"
                              onClick={() => remove_labels(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Button
                      variant="contained"
                      className="label-button"
                      disabled={!!errors?.labels}
                      onClick={() => {
                        append_labels({
                          key: "",
                          value: "",
                        });
                      }}
                    >
                      {t("+ Add Labels")}
                    </Button>
                    <Divider className="divider" />
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Button
                      style={{ marginRight: "4px" }}
                      data-testid="create-form-validator-provider"
                      disabled={!isValid || loading}
                      type="submit"
                      variant="contained"
                    >
                      {loading
                        ? t("loading")
                        : editMode
                        ? t("page__validatorProvider__edit")
                        : t("page__validatorProvider__create")}
                    </Button>
                    <Button
                      variant="outlined"
                      component={Link}
                      to={`${EPRIVATEROUTE.VALIDATOR_PROVIDERS}?selectedProject=${selectedProject.name}`}
                    >
                      {t("cancel")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>

            <Grid item xs={12} md={6} lineHeight={2}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Monthly Estimate
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui,
                  laudantium voluptas optio, ut repellat eum suscipit nulla
                  quidem
                </Typography>
                <Typography
                  variant="subtitle2"
                  lineHeight={2}
                  paddingBottom={2}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Dolorum alias pariatur dolores commodi facilis modi
                  exercitationem doloremque eum deserunt accusantium distinctio
                  temporibus dolor a, expedita perferendis quasi reiciendis.
                  Ipsam, sit!
                </Typography>
                <Button variant="text" component={Link} to={""}>
                  Compute Engine Pricing
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      )}
    </Fragment>
  );
};
