import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ITypeFormData } from "./model/form.model";
import { yupResolver } from "@hookform/resolvers/yup";
import { Validation } from "./validation";
import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";

import { StringInput } from "@src/components/form/string-input";
import { JsonInput } from "@src/components/form/json-input";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { TableSelector } from "@src/components/form/Table-selector";
import { IValidatorWithSelection } from "./model/validator-with-selection.model";
import { IColumn } from "@src/components/entity_table/entity_table";
import { colDef, removeNullValue } from "@src/helpers/utils";
import CheckIcon from "@mui/icons-material/Check";
import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { ValidatorRepository } from "@src/services/validator.repository";
import { IType } from "@src/models/type.model";
import { IEntityFieldError } from "@src/services/repository";

export interface IProps {
  onSubmit: (formValues: ITypeFormData) => void;
  editMode: boolean;
  loading: boolean;
  model: IType | null;
  errorList: Array<IEntityFieldError<IType>> | null;
  repoValidator: ValidatorRepository;
}

export const SLTypeForm: React.FC<IProps> = ({
  onSubmit,
  editMode,
  loading,
  model,
  errorList,
  repoValidator,
}) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const [selectedValidator, setSelectedValidator] =
    useState<IValidatorWithSelection | null>(null);
  const methods = useForm<ITypeFormData>({
    resolver: yupResolver(Validation(t)),
    mode: "onBlur",
    defaultValues: {
      description: null,
    },
  });
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods;
  useEffect(() => {
    if (editMode && model) {
      setSelectedValidator({ ...model.validator_detail!, hasSelectIcon: true });
      setValue("name", model.name);
      setValue("title", model.title);
      setValue("description", model.description);
      setValue(
        "validator_param_values",
        JSON.stringify(model.validator_param_values)
      );
      setValue("validator_id", model.validator_id);
    }
  }, []);

  const fetchValidators = (filter: string) => {
    const newQuery = removeNullValue({
      name: filter,
      project_id: selectedProject.id,
    });
    return repoValidator.getAll(newQuery);
  };

  const validatorDropdownCols: IColumn<IValidatorWithSelection>[] = [
    colDef("hasSelectIcon", "", (_, y) =>
      y.hasSelectIcon ? <CheckIcon className="check-icon" /> : null
    ),
    colDef("name", t("name")),
    colDef("title", t("title")),
  ];

  useEffect(() => {
    if (errorList) {
      errorList.map(
        (item) =>
          item.loc[0] == "body" && setError(item.loc[1], { message: item.msg })
      );
    }
  }, [errorList]);

  const isValid = Object.keys(errors).length == 0;
  const btnTitle = editMode ? t("edit") : t("create");

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          rowSpacing={3}
          direction="column"
          data-testid="stateless-form-component"
        >
          <Grid item>
            <StringInput
              name="name"
              label={t("name")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Link to="">
                      <IconButton color="info">
                        <HelpIcon />
                      </IconButton>
                    </Link>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <StringInput label={t("title")} name="title" />
          </Grid>

          <Grid item>
            {editMode && (
              <TextField
                label={t("page__validator__validator")}
                id="validator_id-validator"
                placeholder={t("page__validator__validator")!}
                type="text"
                variant="outlined"
                fullWidth
                value={model?.validator_detail?.name || model?.validator_id}
                InputProps={{
                  readOnly: editMode,
                }}
              />
            )}
            <Controller
              name="validator_id"
              control={control}
              render={({ field: { onChange, onBlur } }) => (
                <>
                  {!editMode && (
                    <TableSelector<IValidatorWithSelection>
                      onBlur={onBlur}
                      fieldError={errors?.validator_id?.message}
                      renderDisplayValue={(value) => {
                        return value?.name || value?.id || "";
                      }}
                      fetchData={fetchValidators}
                      columns={validatorDropdownCols}
                      onChange={(data: IValidatorWithSelection) => {
                        onChange(data.id);
                        setSelectedValidator(data);
                      }}
                      selectedValue={selectedValidator!}
                      label={t("page__validator__validator")}
                    />
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item>
            <StringInput
              name="description"
              label={t("description")}
              multiline
              rows={3}
            />
          </Grid>
          {getValues("validator_id") && (
            <Grid item>
              <Controller
                name="validator_param_values"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <JsonInput
                    onBlur={onBlur}
                    fieldError={errors?.validator_param_values?.message}
                    onChange={onChange}
                    value={value}
                    label="page__type__validator_param_values"
                  />
                )}
              />
            </Grid>
          )}
          <Grid
            item
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            <Button
              type="submit"
              style={{ marginRight: "4px" }}
              data-testid="create-form-type"
              disabled={!isValid || loading}
              variant="contained"
            >
              {loading ? t("loading") : btnTitle}
            </Button>
            <Button
              variant="outlined"
              component={Link}
              to={`${EPRIVATEROUTE.TYPES}?selectedProject=${selectedProject.name}`}
            >
              {t("cancel")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
