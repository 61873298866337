import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "@redux/store";

export interface ITokenState {
  token: string | undefined;
}

const initialState: ITokenState = {
  token: undefined,
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { setToken } = tokenSlice.actions;

export const getToken = (state: RootState) => state.token.token;

export const tokenReducer = tokenSlice.reducer;
