import { IResult, Repository } from "@src/services/repository";
import {
  IValidatorProvider,
  IValidatorProviderFilter,
  IValidatorProviderImport,
} from "@src/models/validator-provider.model";
export class ValidatorProviderRepository extends Repository<
  IValidatorProvider,
  IValidatorProviderImport,
  IValidatorProviderImport,
  IValidatorProvider,
  IValidatorProviderFilter
> {
  constructor() {
    super("/v1.0/validator_providers");
  }
  async import(
    data: IValidatorProviderImport
  ): Promise<IResult<IValidatorProvider>> {
    const url = "/v1.0/validator_providers/import";
    const rtn = await this.http_client.post<
      IValidatorProviderImport,
      IResult<IValidatorProvider>
    >(url, data, this.headers);
    this.raiseExceptionOnError(rtn);
    return rtn.body;
  }
}
