import { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import { IRoute } from "@src/components/type/route-type";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";

interface INavItem {
  item: IRoute;
  active: (path: string) => boolean;
}
export function NavItem({ item, active }: INavItem) {
  const { t } = useTranslation();
  const isActiveRoot = active(item.path!);

  const { title, path, icon, children } = item;
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev: boolean) => !prev);
  };

  if (children) {
    return (
      <div
        className="SidbarNavItemWrapper"
        data-testid="childrenSidbarNavItemWrapper"
      >
        <ListItemButton
          disabled={selectedProject.name === ""}
          disableGutters
          onClick={handleOpen}
          className={`${isActiveRoot && "activeRootStyle"}`}
        >
          <ListItemIcon className="ListItemIconStyle">
            {icon && icon}
          </ListItemIcon>
          <ListItemText disableTypography primary={`${t(title)}`} />
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item: IRoute) => {
              const { title, path } = item;
              const isActiveSub = active(path!);

              return (
                <ListItemButton
                  disableGutters
                  key={title}
                  component={RouterLink}
                  to={
                    selectedProject.name !== ""
                      ? `${path!}?selectedProject=${selectedProject.name}`
                      : path!
                  }
                  className={`${isActiveSub && "activeSubStyle"}`}
                >
                  <ListItemIcon className="ListItemIconStyle">
                    <Box
                      className={`iconBox${isActiveSub ? " isActiveSub" : ""}`}
                      component="span"
                    />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={`${t(title)}`} />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      </div>
    );
  }

  return (
    <div className="SidbarNavItemWrapper" data-testid="sidbarNavItemWrapper">
      <ListItemButton
        disabled={selectedProject.name === ""}
        disableGutters
        component={RouterLink}
        to={
          selectedProject.name !== ""
            ? `${path!}?selectedProject=${selectedProject.name}`
            : path!
        }
        className={`${isActiveRoot && "activeRootStyle"}`}
      >
        <ListItemIcon className="ListItemIconStyle">
          {icon && icon}
        </ListItemIcon>
        <ListItemText disableTypography primary={`${t(title)}`} />
      </ListItemButton>
    </div>
  );
}
