import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PrivateNavbar } from "@components/layout/private-layout/private-navbar/private-navbar";
import { PrivateSidebar } from "@src/components/layout/private-layout/private-sidebar/private-sidebar";

export const PrivateLayout: React.FC<{}> = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="dashboard" data-testid="dashboard">
      <Helmet>
        <title>dashboard</title>
      </Helmet>
      <PrivateNavbar onOpenSidebar={() => setOpen(true)} />
      <PrivateSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <div className="mainWrapper">
        <Outlet />
      </div>
    </div>
  );
};
