import { ServiceRepository } from "@src/services/service.repository";
import { colDef } from "@src/helpers/utils";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IColumn } from "@src/components/entity_table/entity_table";
import { ITableBulkAction } from "@src/components/type/tabel-bulk-action-type";
import { GenericList } from "@src/components/generic-list/generic-list";
import { IServiceDefinition, IServiceFilter } from "@src/models/service.model";
import { Filter } from "@src/containers/private/service/filter";
import { HeaderList } from "@src/containers/private/service/header";
export interface IProps {
  serviceRepo?: ServiceRepository;
}

export const ServiceList: React.FC<IProps> = ({ serviceRepo }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [bulkSelected, setBulkSelected] = useState<Array<IServiceDefinition>>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [repo, setRepo] = useState<ServiceRepository>(
    serviceRepo || new ServiceRepository()
  );

  const fields: Array<keyof IServiceFilter> = [
    "name",
    "title",
    "since",
    "before",
    "page_size",
  ];
  const query: IServiceFilter = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields.forEach((x) => ((query as any)[x] = searchParams.get(x)));

  useEffect(() => {
    setRepo(serviceRepo || repo);
  }, [serviceRepo != null]);

  const bulkAction: Array<ITableBulkAction> = [
    {
      icon: <DeleteIcon />,
      title: t("delete"),
      isDisabled: false,
      callBack: () => {
        return;
      },
    },
  ];
  const columns: IColumn<IServiceDefinition>[] = [
    colDef("name", t("name")),
    colDef("title", t("title")),
    colDef("description", t("description"), (_, y) =>
      y.description ? y.description : ""
    ),
    colDef("configs", t("page__service__count of config"), (_, y) =>
      y.configs ? y.configs.length : 0
    ),
    colDef("volumes", t("page__service__volumes"), (_, y) =>
      y.volumes ? y.volumes.length : 0
    ),
  ];

  return (
    <Fragment>
      <Helmet>
        <title>{t("page__type__services")}</title>
      </Helmet>
      <ToastContainer />
      <GenericList
        stateError={[error, setError]}
        stateLoading={[loading, setLoading]}
        stateSelectedItems={[bulkSelected, setBulkSelected]}
        bulkActionList={bulkAction}
        bulkSelectedMsgTemplate={
          bulkSelected.length > 1
            ? t("page__service__servicesSelected", {
                num: bulkSelected.length,
              })
            : t("page__service__serviceSelected", {
                num: bulkSelected.length,
              })
        }
        columns={columns}
        HeaderComponent={HeaderList}
        FilterComponent={Filter}
        initQuery={query}
        repo={repo}
        rowActionList={[]}
      />
    </Fragment>
  );
};
