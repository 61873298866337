import i18n from "@src/i18n";
import "@styles/style.scss";
import { OidcProvider } from "@axa-fr/react-oidc";
import { App } from "@app/app-wrapper";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import { reportWebVitals } from "@src/reportWebVitals";
import { store } from "@redux/store";
import { configurationIdentityServer } from "@constants/configuration-identity-server";
import { I18nextProvider } from "react-i18next";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const setupStore = store();
root.render(
  <React.StrictMode>
    <Provider store={setupStore}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <OidcProvider configuration={configurationIdentityServer}>
            <App />
          </OidcProvider>
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
