import { IRoute } from "@src/components/type/route-type";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { EPRIVATEROUTE } from "@constants/enum/private-route.enum";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Hive from "@mui/icons-material/Hive";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { ProjectList } from "@src/containers/private/projects/list";
import { CreateProject } from "@src/containers/private/projects/form";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { EmptyList } from "@src/components/empty-project-list/empty-list";
import { Overview } from "@src/containers/private/overview/overview";
import { Users } from "@src/containers/private/users/users";
import { Setting } from "@src/containers/private/setting/setting";
import { ValidatorProviderList } from "@src/containers/private/validator-provider/list";
import { ImportValidatorProvider } from "@src/containers/private/validator-provider/form";
import { ShowValidatorProvider } from "@src/containers/private/validator-provider/show";
import { Validators } from "@src/containers/private/validator/list";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import { CreateValidator } from "@src/containers/private/validator/form";
import { Types } from "@src/containers/private/type/list";
import { ETITLEROUTE } from "@src/constants/enum/title-route.enum";
import { CreateType } from "@src/containers/private/type/form";
import { ShowValidator } from "@src/containers/private/validator/show";
import { ServiceList } from "@src/containers/private/service/list";
export const privateRoutes: IRoute[] = [
  {
    keyIndex: "overview",
    path: EPRIVATEROUTE.OVERVIEW,
    icon: <DashboardIcon />,
    title: ETITLEROUTE.DASHBOARD,
    element: <Overview />,
    inMenu: true,
  },
  {
    keyIndex: "users",
    path: EPRIVATEROUTE.USER,
    icon: <PeopleAltIcon />,
    title: ETITLEROUTE.USER,
    element: <Users />,
    inMenu: true,
  },
  {
    keyIndex: "setting",
    path: EPRIVATEROUTE.SETTING,
    icon: <LibraryBooksIcon />,
    title: ETITLEROUTE.SETTING,
    element: <Setting />,
    inMenu: true,
  },
  {
    keyIndex: "projects",
    path: EPRIVATEROUTE.PROJECTS,
    icon: <LibraryAddCheckIcon />,
    title: ETITLEROUTE.PROJECTS,
    element: <ProjectList />,
    inMenu: true,
  },
  {
    keyIndex: "create-project",
    path: EPRIVATEROUTE.PROJECTS_CREATE,
    icon: <PeopleAltIcon />,
    title: ETITLEROUTE.PROJECTS_CREATE,
    element: <CreateProject />,
    inMenu: false,
  },
  {
    keyIndex: "edit-project",
    path: EPRIVATEROUTE.PROJECTS_EDIT,
    icon: <PeopleAltIcon />,
    title: ETITLEROUTE.PROJECTS_EDIT,
    element: <CreateProject />,
    inMenu: false,
  },
  {
    keyIndex: "empty-project-list",
    path: EPRIVATEROUTE.EMPTY_PROJECT_LIST,
    icon: <PeopleAltIcon />,
    title: ETITLEROUTE.EMPTY_PROJECT_LIST,
    element: <EmptyList />,
    inMenu: false,
  },
  {
    keyIndex: "validator-providers",
    path: EPRIVATEROUTE.VALIDATOR_PROVIDERS,
    icon: <Hive />,
    title: ETITLEROUTE.VALIDATOR_PROVIDERS,
    element: <ValidatorProviderList />,
    inMenu: true,
  },
  {
    keyIndex: "validator-provider-import",
    path: EPRIVATEROUTE.VALIDATOR_PROVIDERS_IMPORT,
    icon: <></>,
    title: ETITLEROUTE.VALIDATOR_PROVIDERS_IMPORT,
    element: <ImportValidatorProvider />,
    inMenu: false,
  },
  {
    keyIndex: "validator-provider-show",
    path: EPRIVATEROUTE.VALIDATOR_PROVIDER_SHOW,
    icon: <></>,
    title: ETITLEROUTE.VALIDATOR_PROVIDER_SHOW,
    element: <ShowValidatorProvider />,
    inMenu: false,
  },
  {
    keyIndex: "validator-provider-edit",
    path: EPRIVATEROUTE.VALIDATOR_PROVIDER_EDIT,
    icon: <></>,
    title: ETITLEROUTE.VALIDATOR_PROVIDER_EDIT,
    element: <ImportValidatorProvider />,
    inMenu: false,
  },
  {
    keyIndex: "validators",
    path: EPRIVATEROUTE.VALIDATORS,
    icon: <BookmarkAddedIcon />,
    title: ETITLEROUTE.VALIDATORS,
    element: <Validators />,
    inMenu: true,
  },
  {
    keyIndex: "validator-create",
    path: EPRIVATEROUTE.VALIDATOR_CREATE,
    icon: <></>,
    title: ETITLEROUTE.VALIDATOR_CREATE,
    element: <CreateValidator />,
    inMenu: false,
  },
  {
    keyIndex: "validator-edit",
    path: EPRIVATEROUTE.VALIDATOR_EDIT,
    icon: <></>,
    title: ETITLEROUTE.VALIDATOR_EDIT,
    element: <CreateValidator />,
    inMenu: false,
  },
  {
    keyIndex: "validator-show",
    path: EPRIVATEROUTE.VALIDATOR_SHOW,
    icon: <></>,
    title: ETITLEROUTE.VALIDATOR_SHOW,
    element: <ShowValidator />,
    inMenu: false,
  },
  {
    keyIndex: "types",
    path: EPRIVATEROUTE.TYPES,
    icon: <AssignmentTurnedInIcon />,
    title: ETITLEROUTE.TYPES,
    element: <Types />,
    inMenu: true,
  },
  {
    keyIndex: "type-create",
    path: EPRIVATEROUTE.TYPES_CREATE,
    icon: <AssignmentTurnedInIcon />,
    title: ETITLEROUTE.TYPES_CREATE,
    element: <CreateType />,
    inMenu: false,
  },
  {
    keyIndex: "type-edit",
    path: EPRIVATEROUTE.TYPES_EDIT,
    icon: <AssignmentTurnedInIcon />,
    title: ETITLEROUTE.TYPES_EDIT,
    element: <CreateType />,
    inMenu: false,
  },
  {
    keyIndex: "services",
    path: EPRIVATEROUTE.SERVICES,
    icon: <MiscellaneousServicesIcon />,
    title: ETITLEROUTE.SERVICES,
    element: <ServiceList />,
    inMenu: true,
  },
  {
    keyIndex: "service-create",
    path: EPRIVATEROUTE.SERVICES_CREATE,
    icon: <MiscellaneousServicesIcon />,
    title: ETITLEROUTE.SERVICES_CREATE,
    element: <>service create</>,
    inMenu: false,
  },
];
