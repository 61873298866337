import { useLocation } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import { NavSection } from "@src/components/layout/private-layout/sidebar-nav-section/navigation-section";
import { privateRoutes } from "@src/app/private-routes";
import { Logo } from "@src/components/logo/logo";
import { useEffect } from "react";
import { useResponsive } from "@src/helpers/hook/useResponsive";

interface IProps {
  isOpenSidebar: boolean;
  onCloseSidebar?: () => void;
}

export const PrivateSidebar: React.FC<IProps> = ({
  isOpenSidebar,
  onCloseSidebar,
}) => {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar && onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <div className="scrollWrapper">
      <Box className="logoBox">
        <Logo />
      </Box>
      <NavSection navConfig={privateRoutes} />
      <Box className="emptyRow" />
    </div>
  );

  return (
    <div className="sidbarWrapper" data-testid="sidebar-wrapper">
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar}>
          {renderContent}
        </Drawer>
      )}
      {isDesktop && (
        <Drawer open variant="persistent">
          {renderContent}
        </Drawer>
      )}
    </div>
  );
};
