import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import analysis from "@assets/images/analysis.png";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";

import { useTranslation } from "react-i18next";
import { EPUBLICROUTE } from "@src/constants/enum/public-route.enum";

export const EmptyList: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Box className="projects-container" data-testid="projects-container">
      <Container>
        <Stack
          className="add-project-container-for-empty-data "
          direction="column"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
          spacing={2}
        >
          <img src={analysis} alt="analysis" width="6%" height="6%" />
          <Typography variant="h5">
            {t(
              "page__project__for creating a new project just click the button"
            )}
          </Typography>
          <Typography variant="h6">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea eum
            accusantium, eos molestiae, voluptatem sequi numquam nihil vitae
            facere odio quis ipsam mollitia id sunt at explicabo, voluptates
            consequatur ipsum!
            <Button
              data-testid="learnMoreButton"
              variant="text"
              component={Link}
              to={EPUBLICROUTE.HOME}
              endIcon={<OpenInNewIcon />}
            >
              {t("page__project__learn more")}
            </Button>{" "}
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Button
              data-testid="createProjectButton"
              variant="contained"
              component={Link}
              to={EPRIVATEROUTE.PROJECTS_CREATE}
              startIcon={<LibraryAddCheckIcon />}
            >
              {t("page__project__New Project")}
            </Button>
            <Button
              data-testid="quickStart"
              variant="text"
              component={Link}
              to={EPUBLICROUTE.HOME}
              endIcon={<OpenInNewIcon />}
            >
              {t("page__project__TAKE THE QUICK START")}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
