import { ISelect } from "@src/components/type/select-type";
import { EBACKGROUND_TASK_STATUS } from "@src/constants/enum/background-task-status";

export const backgroundTaskStatusOption: Array<ISelect> = [
  {
    value: EBACKGROUND_TASK_STATUS.PROCESSING,
    label: EBACKGROUND_TASK_STATUS.PROCESSING,
  },
  {
    value: EBACKGROUND_TASK_STATUS.CREATED,
    label: EBACKGROUND_TASK_STATUS.CREATED,
  },
  { value: EBACKGROUND_TASK_STATUS.DONE, label: EBACKGROUND_TASK_STATUS.DONE },
  {
    value: EBACKGROUND_TASK_STATUS.ERROR,
    label: EBACKGROUND_TASK_STATUS.ERROR,
  },
];
