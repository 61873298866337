import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IProject } from "@src/models/project.model";
import { ProjectRepository } from "@src/services/project.repository";
import { useParams } from "react-router-dom";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
  ConflictException,
  EntityNotFoundException,
  IEntityFieldError,
  InvalidEntityFields,
} from "@src/services/repository";
import { Loading } from "@src/components/loading";
import { FORM_TOAST_TIMEOUT } from "@src/constants/form";
import { convertArrayToObject, delayCallback } from "@src/helpers/utils";
import { useAppDispatch, useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { setSelectedProject } from "@src/components/layout/private-layout/project-slice";
import { useNavContext } from "@src/app/navigation-context";
import { InformationalSection } from "@src/components/form/informational-section";
import { SLProjectForm } from "./stateless-form";
import { Grid } from "@mui/material";
import { IProjectFormData } from "@containers/private/projects/model/form.model";

interface IProps {
  projectRepo?: ProjectRepository;
}
export const CreateProject: React.FC<IProps> = ({ projectRepo }) => {
  const { t } = useTranslation();
  const navContext = useNavContext();
  const params = useParams();
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject.name
  );
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [model, setModel] = useState<IProject | null>(null);
  const [errors, setErrors] = useState<Array<
    IEntityFieldError<IProject>
  > | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [repo, setRepo] = useState<ProjectRepository>(
    projectRepo || new ProjectRepository()
  );

  const processServerError = (
    errors: unknown,
    toastUnexpectedError = true,
    returnToList = false,
    msgArgs: Record<string, string> = {}
  ) => {
    if (!navigator.onLine) {
      toast.error(t("error__network_error"));
      return;
    }
    if (errors instanceof InvalidEntityFields) {
      setErrors(errors.detail);
      return;
    }
    if (errors instanceof ConflictException) {
      setErrors([
        {
          loc: ["body", "name"],
          msg: t(
            "error__project with this name exist,please choose another name"
          ),
          type: "conflict",
        },
      ]);
      return;
    }
    if (errors instanceof EntityNotFoundException) {
      toast.error(t("message__project is not found", msgArgs));
    }

    toastUnexpectedError &&
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    returnToList &&
      delayCallback(
        () => navContext(EPRIVATEROUTE.PROJECTS),
        FORM_TOAST_TIMEOUT
      );
  };
  useEffect(() => {
    setRepo(projectRepo || repo);
  }, [projectRepo != null]);

  const getProjectById = async (id: string) => {
    setFetchLoading(true);
    try {
      const { data: project } = await repo.getById(id);
      setModel(project);
    } catch (errors) {
      processServerError(errors, true, true, { id: id });
    } finally {
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    if (params.project_id) {
      setEditMode(true);
      getProjectById(params.project_id);
    }
  }, [params.project_id]);

  const createProject = async (formValues: IProject) => {
    setLoading(true);
    try {
      const res = await repo.create(formValues);
      !selectedProject && dispatch(setSelectedProject(res.data));
      navContext(EPRIVATEROUTE.PROJECTS);
    } catch (errors) {
      processServerError(errors);
    } finally {
      setLoading(false);
    }
  };
  const editProject = async (formValues: IProject) => {
    setLoading(true);
    const newData = { ...model, ...formValues };
    try {
      await repo.update(newData);
      navContext(EPRIVATEROUTE.PROJECTS);
    } catch (errors) {
      processServerError(errors, true, true, { id: params.project_id! });
    } finally {
      setLoading(false);
    }
  };
  const onSubmit = (formValues: IProjectFormData) => {
    const labelsArray = formValues.labels || [];
    const convertedLabel = convertArrayToObject(labelsArray);
    const formData = { ...formValues, labels: convertedLabel };
    if (editMode) {
      editProject(formData);
    } else {
      createProject(formData);
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>{t("create")}</title>
      </Helmet>
      <ToastContainer autoClose={FORM_TOAST_TIMEOUT} />
      {fetchLoading ? (
        <Loading />
      ) : (
        <div className="create-project-wrapper">
          <Grid container spacing={3} data-testid="create-project-wrapper">
            <Grid item xs={12} md={6}>
              <SLProjectForm
                editMode={editMode}
                errorList={errors}
                loading={loading}
                onSubmit={onSubmit}
                model={model}
              />
            </Grid>
            <InformationalSection />
          </Grid>
        </div>
      )}
    </Fragment>
  );
};
