import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Grid, Typography } from "@mui/material";
import {
  EntityTable,
  IColumn,
  ITableAction,
} from "@src/components/entity_table/entity_table";
import {
  hasNonNullableProperties,
  removeNullValue,
  updateUrlParamsWithQuery,
} from "@src/helpers/utils";
import { IEntityModel } from "@src/models/entity-model.model";
import { IFilterParams, Repository } from "@src/services/repository";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useTranslation } from "react-i18next";
import { ITableBulkAction } from "../type/tabel-bulk-action-type";
import { Footer } from "../list-footer/footer";
import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { AxiosError } from "axios";
import { useNavContext } from "@src/app/navigation-context";
export interface IFilterComponentProps<TFilter extends IFilterParams> {
  handelQuery: (data: TFilter) => void;
  defaultValue: TFilter;
}
export interface IHeaderComponentProps {
  handleRefresh?: () => void;
  loading?: boolean;
}
export interface IProps<T extends IEntityModel, TFilter extends IFilterParams> {
  stateLoading: [boolean, (loading: boolean) => void];
  stateError: [boolean, (hasError: boolean) => void];
  stateSelectedItems: [Array<T>, (items: Array<T>) => void];
  initQuery: TFilter;
  FilterComponent: React.ComponentType<IFilterComponentProps<TFilter>>;
  HeaderComponent: React.ComponentType<IHeaderComponentProps>;
  bulkSelectedMsgTemplate: string;
  bulkActionList: Array<ITableBulkAction>;
  rowActionList: Array<ITableAction<T>>;
  columns: Array<IColumn<T>>;
  repo: Repository<T, T, T, T, TFilter>;
  nestedFields?: Array<string>;
}

export function GenericList<
  T extends IEntityModel,
  TFilter extends IFilterParams
>(props: IProps<T, TFilter>) {
  const {
    bulkSelectedMsgTemplate,
    bulkActionList,
    columns,
    rowActionList,
    HeaderComponent,
    repo,
    initQuery,
    nestedFields,
    FilterComponent,
    stateLoading: [loading, setLoading],
    stateError: [error, setError],
    stateSelectedItems: [selectedItems, setSelectedItems],
  } = props;

  const { t } = useTranslation();
  const navContext = useNavContext();

  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );

  const [records, setRecords] = useState<Array<T>>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [query, setQuery] = useState<TFilter>(initQuery);
  const [showFilterForm, setShowFilterForm] = useState<boolean>(
    hasNonNullableProperties(query)
  );

  useEffect(() => {
    applyQuery();
  }, [query, repo, refresh]);

  const applyQuery = async () => {
    setLoading(true);
    setError(false);
    const validQuery = removeNullValue(query);
    try {
      const { data: records } = await repo.getAll({
        ...validQuery,
        project_id: selectedProject.id,
        nested_fields: nestedFields,
      });
      setRecords(records);
    } catch (error) {
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
      if (
        error instanceof AxiosError &&
        (error.message === "Network Error" ||
          error.message === "Request failed with status code 504" ||
          error.message === "Request failed with status code 500")
      ) {
        setError(true);
      }
    } finally {
      setLoading(false);
      const urlParams = new URLSearchParams(location.search);
      const newUrl = updateUrlParamsWithQuery<TFilter>(
        query,
        urlParams,
        location.pathname
      );
      if (location.pathname + location.search !== newUrl) {
        navContext(newUrl);
      }
    }
  };

  return (
    <div className="list-container" data-testid="list-container">
      <HeaderComponent
        loading={loading}
        handleRefresh={() => setRefresh(!refresh)}
      />
      <Box className="filter-table-content" data-testid="filter-table-content">
        <Grid borderBottom={1} borderColor={"#e0e0e0"}>
          <Grid item className="filter-icon">
            {showFilterForm ? (
              <ArrowDropDownIcon
                onClick={() => setShowFilterForm(!showFilterForm)}
              />
            ) : (
              <ArrowDropUpIcon
                onClick={() => setShowFilterForm(!showFilterForm)}
              />
            )}
            <Typography>{t("filter")}</Typography>
          </Grid>
          <Grid item>
            {showFilterForm && (
              <FilterComponent
                defaultValue={{ ...query }}
                handelQuery={setQuery}
              />
            )}
          </Grid>
        </Grid>
        <EntityTable<T>
          bulkeSelectedMessage={bulkSelectedMsgTemplate}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          showCheckbox={true}
          bulkActions={bulkActionList}
          error={error}
          loading={loading}
          tableColumns={columns}
          dataList={records}
          actions={rowActionList}
        />
        {error ? (
          <></>
        ) : (
          <Footer<TFilter>
            query={query}
            loading={loading}
            nextState={repo.getPaginationState().next || ""}
            prevState={repo.getPaginationState().prev || ""}
            handleChange={setQuery}
          />
        )}
      </Box>
    </div>
  );
}
