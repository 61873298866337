import { Popover } from "@mui/material";

interface IProps {
  children: React.ReactNode;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose?: () => void;
}

export const MenuPopover: React.FC<IProps> = ({
  children,
  open,
  anchorEl,
  handleClose,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <span className="arrow " />

      {children}
    </Popover>
  );
};
