import React, { useEffect, useState } from "react";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { ProjectRepository } from "@src/services/project.repository";
import { useTranslation } from "react-i18next";
import { IProject, IProjectFilters } from "@src/models/project.model";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { setSelectedProject } from "@components/layout/private-layout/project-slice";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Container, Stack, Typography } from "@mui/material";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { useAppDispatch, useAppSelector } from "@src/redux/hooks";
import {
  EntityTable,
  IColumn,
} from "@src/components/entity_table/entity_table";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { SearchBox } from "../searchBox/searchBox";
import { colDef, removeNullValue } from "@src/helpers/utils";
import { IReduxState } from "@src/redux/root-reducer";
interface IProps {
  projectRepo?: ProjectRepository;
}
interface IProjectWithSelection extends IProject {
  hasSelectIcon?: boolean;
}
export const ProjectDropDown: React.FC<IProps> = ({ projectRepo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const reduxSelectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const [error, setError] = useState<boolean>(false);
  const [fetchProjectsList, setFetchProjectList] = useState<
    Array<IProjectWithSelection>
  >([]);
  const [query, setQuery] = useState<IProjectFilters>({ name: "" });
  const [repo, setRepo] = useState<ProjectRepository>(
    projectRepo || new ProjectRepository()
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const updateReduxAndUrl = (
    currentSelectedProject: IProject,
    projectsWithSelection: Array<IProjectWithSelection>
  ) => {
    setFetchProjectList(projectsWithSelection);
    if (reduxSelectedProject.name !== currentSelectedProject.name) {
      dispatch(setSelectedProject(currentSelectedProject));
      navigate(
        `${EPRIVATEROUTE.OVERVIEW}?selectedProject=${currentSelectedProject.name}`
      );
    }
  };

  const columns: IColumn<IProjectWithSelection>[] = [
    colDef("hasSelectIcon", t(""), (_, y) =>
      y.hasSelectIcon ? <CheckIcon className="check-icon" /> : null
    ),
    colDef<IProjectWithSelection>("name", t("name")),
    colDef<IProjectWithSelection>("title", t("title")),
    colDef("description", t("description"), (_, y) =>
      y.description ? y.description : ""
    ),
  ];

  const clearSearchAndCloseModal = () => {
    setShowModal(false);
    setQuery({ name: "" });
  };
  const handleClick = (selectedItem: IProjectWithSelection) => {
    const newSelectedList: Array<IProjectWithSelection> = fetchProjectsList.map(
      (i: IProject) => {
        return { ...i, hasSelectIcon: i.name === selectedItem.name };
      }
    );
    const selectItemWithoutIcon = { ...selectedItem };
    delete selectItemWithoutIcon.hasSelectIcon;
    updateReduxAndUrl(selectItemWithoutIcon, newSelectedList);
    clearSearchAndCloseModal();
  };
  const fetchProjects = async () => {
    setLoading(true);
    const validQuery = removeNullValue(query);
    try {
      const { data } = await repo.getAll({ ...validQuery, page_size: 100 });
      const fetchProjectsWithSelection: Array<IProjectWithSelection> = data.map(
        (i: IProject) => {
          return { ...i, hasSelectIcon: i.name === reduxSelectedProject.name };
        }
      );
      setFetchProjectList(fetchProjectsWithSelection);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  const handelClickCreateButton = () => {
    navigate(EPRIVATEROUTE.PROJECTS_CREATE);
    clearSearchAndCloseModal();
  };

  useEffect(() => {
    showModal && fetchProjects();
  }, [query]);
  useEffect(() => {
    setRepo(projectRepo || repo);
  }, [projectRepo != null]);
  return (
    <div data-testid="dropdown-project" className="dropdown-project">
      <div
        data-testid="dropdown-project-content"
        className="dropdown-project-content"
        onClick={() => {
          fetchProjects();
          setShowModal(true);
        }}
      >
        <div className="box">
          <BubbleChartIcon />
        </div>
        <span className="select-project">{reduxSelectedProject.name}</span>
        <div className="box">
          <ArrowDropDownIcon />
        </div>
      </div>
      <Dialog
        fullWidth={true}
        open={showModal}
        onClose={() => clearSearchAndCloseModal()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Container>
          <Stack
            marginTop={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={4}
          >
            <Typography variant="h5">{t("select project")}</Typography>
            <Button
              data-testid="dropdown-modal-create-button"
              disabled={loading}
              variant="text"
              onClick={() => handelClickCreateButton()}
              startIcon={<AddCommentIcon />}
            >
              {t("page__project__New Project")}
            </Button>
          </Stack>
          <Stack>
            <SearchBox
              customPlaceHolder="search projects"
              onChange={(filter: string) => {
                setQuery({ name: filter });
              }}
            />
          </Stack>
        </Container>
        <DialogContent>
          <EntityTable<IProjectWithSelection>
            bulkeSelectedMessage=""
            error={error}
            loading={loading}
            tableColumns={columns}
            dataList={fetchProjectsList}
            onRowClick={handleClick}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => clearSearchAndCloseModal()}
            autoFocus
            data-testid="confirm-agree"
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
