export enum ETITLEROUTE {
  OVERVIEW = "route__overview",
  USER = "route__user",
  SETTING = "route__settings",
  PROFILE = "route__profile",
  DASHBOARD = "route__dashboard",
  PROJECTS = "route__projects",
  PROJECTS_CREATE = "route__projects_create",
  EMPTY_PROJECT_LIST = "route__emptyProject",
  PROJECTS_EDIT = "route__projects_edit",
  VALIDATOR_PROVIDERS = "route__validator_providers",
  VALIDATOR_PROVIDERS_IMPORT = "route__validator_providers_import",
  VALIDATOR_PROVIDER_EDIT = "route__validator_providers_edit",
  VALIDATOR_PROVIDER_SHOW = "route__validator_providers_show",
  VALIDATOR_SHOW = "route__validator_show",
  VALIDATORS = "route__validators",
  VALIDATOR_CREATE = "route__validators_create",
  VALIDATOR_EDIT = "route__validators_edit",
  TYPES = "route__types",
  TYPES_CREATE = "route__type_create",
  TYPES_EDIT = "route_type_edit",
  SERVICES = "route__services",
  SERVICES_CREATE = "route__service_create",
}
