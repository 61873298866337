import { AppBar, Box, IconButton, Stack, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AccountPopover } from "@components/layout/private-layout/account-popover/account-popover";
import { ProjectDropDown } from "@components/layout/private-layout/project-dropdown/project-dropdown";

interface IProps {
  onOpenSidebar?: () => void;
}

export const PrivateNavbar: React.FC<IProps> = ({ onOpenSidebar }) => {
  return (
    <AppBar className="dashboardNavbar" data-testid="dashboardNavbar">
      <Toolbar className="toolbarStyle">
        <IconButton
          aria-label="dashboardIcon"
          onClick={onOpenSidebar}
          className="dashboardIcon"
        >
          <MenuIcon />
        </IconButton>
        <Box className="flex-grow-1">
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            <ProjectDropDown />
          </Stack>
        </Box>
        <Box className="flex-grow-1" />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <AccountPopover />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
