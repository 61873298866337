import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "@redux/store";
import { getCookieLanguages } from "@helpers/utils";

export interface ILanguageState {
  language: string;
}

const initialState: ILanguageState = {
  language: getCookieLanguages() || "en",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setSelectLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

export const { setSelectLanguage } = languageSlice.actions;

export const getSelectLanguage = (state: RootState) => state.language.language;
export const languageReducer = languageSlice.reducer;
