import { IResult, Repository } from "@src/services/repository";
import { IValidator, IValidatorFilter } from "@src/models/validator.model";
import qs from "qs";
export enum NESTED_FIELD {
  VALIDATOR_PROVIDER = "validator_provider",
}
export class ValidatorRepository extends Repository<
  IValidator,
  IValidator,
  IValidator,
  IValidator,
  IValidatorFilter
> {
  constructor() {
    super("/v1.0/validators");
  }
  async getById(
    id: string,
    nested_fields?: Array<NESTED_FIELD> | null
  ): Promise<IResult<IValidator>> {
    const url = `/v1.0/validators/${id}?${qs.stringify(
      { nested_fields },
      {
        indices: false,
      }
    )}`;
    const rtn = await this.http_client.get<IResult<IValidator>>(
      url,
      this.headers
    );
    this.raiseExceptionOnError(rtn);
    return rtn.body;
  }
}
