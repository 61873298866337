import {
  colDef,
  removeNullValue,
  updateUrlParamsWithQuery,
} from "@src/helpers/utils";
import {
  IValidatorProvider,
  IValidatorProviderFilter,
} from "@src/models/validator-provider.model";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { ValidatorProviderRepository } from "@src/services/validator-provider.repository";
import { AxiosError } from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { HeaderList } from "@src/containers/private/validator-provider/header";
import { Box, Grid, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  EntityTable,
  IColumn,
  ITableAction,
} from "@src/components/entity_table/entity_table";
import { Filter } from "@src/containers/private/validator-provider/filter";
import { EBACKGROUND_TASK_STATUS } from "@src/constants/enum/background-task-status";
import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { Footer } from "@src/components/list-footer/footer";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { EMODAL_ACTION } from "@src/constants/enum/confirm-modal-action";
import { ConfirmModal } from "@src/components/confirm_modal";
import { EntityNotFoundException } from "@src/services/repository";
import { useNavContext } from "@src/app/navigation-context";
export interface IProps {
  validatorProviderRepo?: ValidatorProviderRepository;
}
const default_page_size = 10;

export const ValidatorProviderList: React.FC<IProps> = ({
  validatorProviderRepo,
}) => {
  const { t } = useTranslation();
  const navContext = useNavContext();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const url = location.pathname;
  const name = searchParams.get("name");
  const title = searchParams.get("title");
  const background_task_status = searchParams.get("background_task_status");
  let convertedStatus: EBACKGROUND_TASK_STATUS | null | undefined;
  if (background_task_status) {
    convertedStatus = background_task_status as EBACKGROUND_TASK_STATUS;
  } else {
    convertedStatus = null;
  }
  const endpoint = searchParams.get("endpoint");
  const since = searchParams.get("since");
  const before = searchParams.get("before");
  const page_size = Number(searchParams.get("page_size"));
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const [validatorProviders, setValidatorProviders] =
    useState<Array<IValidatorProvider> | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedDeleteData, setSelectedDeleteData] =
    useState<IValidatorProvider>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [query, setQuery] = useState<IValidatorProviderFilter>({
    name: name,
    endpoint: endpoint,
    title: title,
    background_task_status: convertedStatus,
    since: since,
    before: before,
    page_size: page_size || default_page_size,
  });
  const [showFilterForm, setShowFilterForm] = useState<boolean>(
    query.name !== null ||
      query.title !== null ||
      query.endpoint !== null ||
      query.background_task_status !== null
  );
  const [repo, setRepo] = useState<ValidatorProviderRepository>(
    validatorProviderRepo || new ValidatorProviderRepository()
  );

  const actionList: Array<ITableAction<IValidatorProvider>> = [
    {
      icon: <DeleteIcon />,
      title: t("delete"),
      action: (data: IValidatorProvider) => {
        setShowDeleteModal(true);
        setSelectedDeleteData(data);
      },
    },
    {
      icon: <ModeEditIcon />,
      title: t("edit"),
      action: (data: IValidatorProvider) => {
        navContext(`${EPRIVATEROUTE.VALIDATOR_PROVIDERS}/${data.id}`);
      },
      isDisabled: (data: IValidatorProvider) => {
        if (
          data.background_task_status === EBACKGROUND_TASK_STATUS.DONE ||
          data.background_task_status === EBACKGROUND_TASK_STATUS.ERROR
        ) {
          return false;
        }
        return true;
      },
    },
  ];
  const getStatusColor = (status: EBACKGROUND_TASK_STATUS) => {
    switch (status) {
      case (status = EBACKGROUND_TASK_STATUS.DONE):
        return <span className="done">{status}</span>;
      case (status = EBACKGROUND_TASK_STATUS.CREATED):
        return <span className="create">{status}</span>;
      case (status = EBACKGROUND_TASK_STATUS.ERROR):
        return <span className="error">{status}</span>;
      case (status = EBACKGROUND_TASK_STATUS.PROCESSING):
        return <span className="processing">{status}</span>;
      default:
        return <span className="Done">{status}</span>;
    }
  };
  const columns: IColumn<IValidatorProvider>[] = [
    colDef<IValidatorProvider>("name", t("name")),
    colDef<IValidatorProvider>("title", t("title")),
    colDef<IValidatorProvider>(
      "endpoint",
      t("page__validatorProvider__endpoint"),
      (_, y) => <span>{y.endpoint}</span>
    ),
    colDef<IValidatorProvider>(
      "background_task_status",
      t("page__validatorProvider__background_task_status"),
      (_, y) => getStatusColor(y.background_task_status!)
    ),
  ];

  useEffect(() => {
    setRepo(validatorProviderRepo || repo);
  }, [validatorProviderRepo != null]);

  const applyQuery = async () => {
    setLoading(true);
    setError(false);
    const validQuery = removeNullValue(query);

    try {
      const { data: validatorProviders } = await repo.getAll({
        ...validQuery,
        project_id: selectedProject.id,
      });
      setValidatorProviders(validatorProviders);
    } catch (error) {
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
      if (
        error instanceof AxiosError &&
        (error.message === "Network Error" ||
          error.message === "Request failed with status code 504" ||
          error.message === "Request failed with status code 500")
      ) {
        setError(true);
      }
    } finally {
      setLoading(false);
      const urlParams = new URLSearchParams(location.search);
      const newUrl = updateUrlParamsWithQuery<IValidatorProviderFilter>(
        query,
        urlParams,
        url
      );
      if (location.pathname + location.search !== newUrl) {
        navContext(newUrl);
      }
    }
  };
  const deleteValidatorProvider = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await repo.delete(selectedDeleteData!);
      if (res) {
        toast.success(
          t("message__your request has been successfully completed")
        );
        setRefresh(!refresh);
      }
    } catch (error) {
      if (error instanceof EntityNotFoundException) {
        toast.success(
          t("message__your request has been successfully completed")
        );
        return;
      }
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };
  const getActionModal = (data: EMODAL_ACTION) => {
    switch (data) {
      case EMODAL_ACTION.YES:
        deleteValidatorProvider();
        break;
      default:
        setShowDeleteModal(false);
    }
  };
  useEffect(() => {
    applyQuery();
  }, [query, repo, refresh]);
  return (
    <Fragment>
      <Helmet>
        <title>{t("page__validatorProvider__validatorProvider")}</title>
      </Helmet>
      <ToastContainer />
      <div
        className="validator-provider-container"
        data-testid="validator-provider-container"
      >
        <HeaderList
          loading={loading}
          handleRefresh={() => setRefresh(!refresh)}
        />
        <Box
          className="filter-table-content"
          data-testid="filter-table-content"
        >
          <Grid borderBottom={1} borderColor={"#e0e0e0"}>
            <Grid item className="filter-icon">
              {showFilterForm ? (
                <ArrowDropDownIcon
                  onClick={() => setShowFilterForm(!showFilterForm)}
                />
              ) : (
                <ArrowDropUpIcon
                  onClick={() => setShowFilterForm(!showFilterForm)}
                />
              )}
              <Typography>{t("filter")}</Typography>
            </Grid>
            <Grid item>
              {showFilterForm && (
                <Filter
                  defaultValue={{ ...query }}
                  resetFilter={() => {
                    setQuery({
                      page_size: default_page_size,
                      name: null,
                      title: null,
                      endpoint: null,
                      background_task_status: null,
                      since: null,
                      before: null,
                    });
                  }}
                  handleFormData={(newQuery: IValidatorProviderFilter) => {
                    setQuery({ ...query, ...newQuery });
                  }}
                />
              )}
            </Grid>
          </Grid>
          <EntityTable<IValidatorProvider>
            bulkeSelectedMessage=""
            showCheckbox={true}
            error={error}
            loading={loading}
            tableColumns={columns}
            dataList={validatorProviders || []}
            actions={actionList}
          />
          {error ? (
            <></>
          ) : (
            <Footer<IValidatorProviderFilter>
              query={query}
              loading={loading}
              nextState={repo.getPaginationState().next || ""}
              prevState={repo.getPaginationState().prev || ""}
              handleChange={(query: IValidatorProviderFilter) =>
                setQuery(query)
              }
            />
          )}
        </Box>
      </div>
      <ConfirmModal
        show={showDeleteModal}
        title={t("page__validatorProvider__delete validator providers", {
          name: selectedDeleteData?.name,
        })}
        text={t(
          "page__validatorProvider__are you sure to delete validator providers",
          {
            name: selectedDeleteData?.name,
          }
        )}
        callBack={getActionModal}
        yes={"delete"}
        no={"cancel"}
      />
    </Fragment>
  );
};
