import { configureStore, PreloadedState } from "@reduxjs/toolkit";
import { rootReducer } from "@redux/root-reducer";
export type RootState = ReturnType<typeof rootReducer>;

export function store(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type AppStore = ReturnType<typeof store>;
export type AppDispatch = AppStore["dispatch"];
