import React, { useRef, useState } from "react";
import { Box, MenuItem, Stack, IconButton } from "@mui/material";
import { MenuPopover } from "@src/components/menu-popover/menu-popover";
import english from "@assets/images/flags/ic_flag_en.svg";
import french from "@assets/images/flags/ic_flag_fr.svg";
interface ILanguage {
  value: string;
  label: string;
  icon: string;
}

interface LanguageSelectProps {
  value: string;
  handleChange: (selectedLanguage: string) => void;
}

const LanguageOption: ILanguage[] = [
  {
    value: "en",
    label: "English",
    icon: english,
  },

  {
    value: "fr",
    label: "French",
    icon: french,
  },
];

export const LanguagePopover: React.FC<LanguageSelectProps> = ({
  value,
  handleChange,
}) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState<ILanguage>(
    LanguageOption.find((lang) => lang.value === value) || LanguageOption[0]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = (lang: ILanguage) => {
    setSelectedLang(lang);
    handleChange(lang.value);
    handleClose();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        data-testid="selected-language-flag"
      >
        <img src={selectedLang.icon} alt={selectedLang.label} />
      </IconButton>

      <MenuPopover
        open={open}
        handleClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Stack spacing={0.75}>
          {LanguageOption.map((option: ILanguage, index: number) => (
            <MenuItem
              key={`${option.label}_${index}`}
              selected={option.value === selectedLang.value}
              onClick={() => handleLanguageChange(option)}
            >
              <Box
                component="img"
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
};
