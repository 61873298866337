import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Prism from "prismjs";
import "prismjs/components/prism-json";
import { Noop } from "react-hook-form";

interface CustomInputProps {
  label: string;
  value: string | undefined;
  fieldError?: string;
  onChange?: (data: string) => void;
  onBlur?: Noop;
  readOnly?: boolean;
}

export const JsonInput: React.FC<CustomInputProps> = ({
  label,
  value,
  onChange,
  fieldError,
  onBlur,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => {
    if (!readOnly && onBlur) {
      onBlur();
    }
    setIsFocused(false);
  };

  const preRef = useRef<HTMLPreElement>(document.createElement("pre"));
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!readOnly && onChange) {
      const { value } = event.target;
      onChange(value);
    }
  };

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  useEffect(() => {
    preRef.current.textContent = value as string;
    Prism.highlightElement(preRef.current);
  }, [value]);
  return (
    <>
      <div
        data-testid={`${label}_jsonInput`}
        className={`json-input ${isFocused || value ? "focused" : ""} ${
          fieldError ? "error" : ""
        }`}
      >
        <label>{t(`${label}`)}</label>
        <div className="baseInput">
          <pre
            data-testid={`${label}_pre-json-highlighter`}
            className="language-json"
            ref={preRef}
            tabIndex={0}
          >
            <code className="language-json" ref={preRef}></code>
          </pre>
          <textarea
            readOnly={readOnly}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            id="json"
            data-testid={`${label}_json-textarea`}
            spellCheck={false}
            onChange={handleInputChange}
            value={value}
          ></textarea>
        </div>

        <p
          data-testid={`${label}_error`}
          className={`${fieldError ? "errorMessage" : "hide"}`}
        >
          {fieldError}
        </p>
      </div>
    </>
  );
};
