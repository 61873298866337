import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";

import { Link } from "react-router-dom";
import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";

interface IProps {
  handleRefresh?: () => void;
  loading?: boolean;
}
export const HeaderList: React.FC<IProps> = ({
  handleRefresh,
  loading = false,
}) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
      className="validator-header"
      data-testid="validator-header"
    >
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <BookmarkAddedIcon />
        <Typography variant="h4">{t("page__validator__validator")}</Typography>
      </Stack>
      <Button
        disabled={loading}
        data-testid="create-validator"
        variant="text"
        component={Link}
        to={`${EPRIVATEROUTE.VALIDATOR_CREATE}?selectedProject=${selectedProject.name}`}
        startIcon={<AddCommentIcon />}
      >
        {t("page__validator__newValidator")}
      </Button>
      <Button
        disabled={loading}
        data-testid="refreshPage"
        variant="text"
        onClick={handleRefresh}
        startIcon={<RefreshIcon />}
      >
        {t("refresh")}
      </Button>
    </Stack>
  );
};
