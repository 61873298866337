import { EntityNotFoundException } from "@src/services/repository";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FORM_TOAST_TIMEOUT } from "@src/constants/form";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Loading } from "@src/components/loading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Grid, TextField } from "@mui/material";
import {
  NESTED_FIELD,
  ValidatorRepository,
} from "@src/services/validator.repository";
import { IValidator } from "@src/models/validator.model";
import { InformationalSection } from "@src/components/form/informational-section";
import { JsonInput } from "@src/components/form/json-input";

export interface IProps {
  validatorRepo?: ValidatorRepository;
}
export const ShowValidator: React.FC<IProps> = ({ validatorRepo }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [validator, setValidator] = useState<IValidator>({
    id: "",
    description: "",
    category: "",
    name: "",
    title: "",
    result_schema: {},
    query_parameters_schema: {},
    validator_provider_id: "",
  });
  const [repo, setRepo] = useState<ValidatorRepository>(
    validatorRepo || new ValidatorRepository()
  );
  const getValidatorById = async (id: string) => {
    setLoading(true);
    try {
      const { data: validator } = await repo.getById(id, [
        NESTED_FIELD.VALIDATOR_PROVIDER,
      ]);
      setValidator(validator);
    } catch (errors) {
      if (!navigator.onLine) {
        toast.error(t("error__network_error"));
        return;
      }
      if (errors instanceof EntityNotFoundException) {
        toast.error(t("message__validator is not found", validator.id));
        return;
      } else {
        toast.error(
          t(
            "message__an unexpected error happened, please try again after awhile"
          )
        );
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setRepo(validatorRepo || repo);
  }, [validatorRepo != null]);
  useEffect(() => {
    if (
      params.validator_id &&
      params.validator_id !== "undefined" &&
      params.validator_id !== "null"
    ) {
      getValidatorById(params.validator_id);
    } else {
      toast.error(t("message__id is not valid"));
    }
  }, [params.validator_id]);
  return (
    <Fragment>
      <Helmet>
        <title>{t("page__validatorProvider__show")}</title>
      </Helmet>
      <ToastContainer autoClose={FORM_TOAST_TIMEOUT} />
      {loading ? (
        <Loading />
      ) : (
        <div className="create-validator-wrapper">
          <Grid container spacing={3} data-testid="show-validator-wrapper">
            <Grid item xs={12} md={6}>
              <form>
                <Grid container spacing={2} rowSpacing={3} direction="column">
                  <Grid item>
                    <TextField
                      value={validator.name}
                      label={t("name")}
                      id="name-validator"
                      placeholder={t("name")!}
                      type="text"
                      variant="outlined"
                      fullWidth
                      aria-readonly
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      value={validator.title}
                      label={t("title")}
                      id="title-validator"
                      placeholder={t("title")!}
                      type="text"
                      variant="outlined"
                      fullWidth
                      aria-readonly
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      value={validator.category}
                      label={t("page__validator__category")}
                      id="category-validatorPro"
                      placeholder={t("page__validator__category")!}
                      type="text"
                      variant="outlined"
                      fullWidth
                      aria-readonly
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      value={validator.description || ""}
                      multiline
                      label={t("description")}
                      id="description-validator"
                      variant="outlined"
                      type="text"
                      fullWidth
                      rows={3}
                      aria-readonly
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t("page__validatorProvider__validatorProvider")}
                      id="validator_provider_id-validator"
                      placeholder={
                        t("page__validatorProvider__validatorProvider")!
                      }
                      type="text"
                      variant="outlined"
                      fullWidth
                      value={
                        validator.validator_provider_detail?.name ||
                        validator.validator_provider_detail
                      }
                    />
                  </Grid>
                  <Grid item>
                    <JsonInput
                      value={JSON.stringify(validator.query_parameters_schema)}
                      label="page__validator__query_parameters_schema"
                      readOnly={true}
                    />
                  </Grid>
                  <Grid item>
                    <JsonInput
                      value={JSON.stringify(validator.result_schema)}
                      label="page__validator__result_schema"
                      readOnly={true}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Button
                      data-testid="back-button"
                      variant="outlined"
                      onClick={() => window.history.back()}
                    >
                      <ArrowBackIcon />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <InformationalSection />
          </Grid>
        </div>
      )}
    </Fragment>
  );
};
