import {
  AnyAction,
  Reducer,
  ReducersMapObject,
  combineReducers,
} from "@reduxjs/toolkit";
import { ILanguageState, languageReducer } from "@src/app/language-slice";
import { ITokenState, tokenReducer } from "@src/app/token-slice";
import {
  IProjectState,
  projectReducer,
} from "@src/components/layout/private-layout/project-slice";
import {
  IUserState,
  userReducer,
} from "@src/components/layout/private-layout/user-slice";

export interface IReduxState {
  language: ILanguageState;
  token: ITokenState;
  user: IUserState;
  project: IProjectState;
}
const reducers: ReducersMapObject<IReduxState, AnyAction> = {
  language: languageReducer as Reducer<ILanguageState, AnyAction>,
  token: tokenReducer as Reducer<ITokenState, AnyAction>,
  user: userReducer as Reducer<IUserState, AnyAction>,
  project: projectReducer as Reducer<IProjectState, AnyAction>,
};

export const rootReducer = combineReducers(reducers);
