import { Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {
  ConflictException,
  EntityNotFoundException,
  IEntityFieldError,
  InvalidEntityFields,
} from "@src/services/repository";
import { FORM_TOAST_TIMEOUT } from "@src/constants/form";
import { delayCallback } from "@src/helpers/utils";
import { ValidatorRepository } from "@src/services/validator.repository";
import { IValidator } from "@src/models/validator.model";
import { IReduxState } from "@src/redux/root-reducer";
import { useAppSelector } from "@src/redux/hooks";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import Ajv from "ajv";
import { InformationalSection } from "@src/components/form/informational-section";
import { SLTypeForm } from "@src/containers/private/type/stateless-form";
import {
  TYPE_NESTED_FIELD,
  TypeRepository,
} from "@src/services/type.repository";
import { IType } from "@src/models/type.model";
import { ITypeFormData } from "@containers/private/type/model/form.model";
import { Loading } from "@src/components/loading";
import { useNavContext } from "@src/app/navigation-context";
export interface IProps {
  validatorRepo?: ValidatorRepository;
  typeRepo?: TypeRepository;
}
export const CreateType: React.FC<IProps> = ({ validatorRepo, typeRepo }) => {
  const navContext = useNavContext();
  const { t } = useTranslation();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const ajv = new Ajv({ allErrors: true });
  const params = useParams();
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [model, setModel] = useState<IType | null>(null);
  const [repoValidator, setRepoValidator] = useState<ValidatorRepository>(
    validatorRepo || new ValidatorRepository()
  );
  const [errors, setErrors] = useState<Array<IEntityFieldError<IType>> | null>(
    null
  );
  const [repoType, setRepoType] = useState<TypeRepository>(
    typeRepo || new TypeRepository()
  );
  useEffect(() => {
    if (params.type_id) {
      setEditMode(true);
      getTypeById(params.type_id);
    }
  }, [params.type_id]);
  useEffect(() => {
    setRepoValidator(validatorRepo || repoValidator);
  }, [validatorRepo != null]);
  useEffect(() => {
    setRepoType(typeRepo || repoType);
  }, [typeRepo != null]);
  const processServerError = (
    errors: unknown,
    toastUnexpectedError = true,
    returnToList = false,
    msgArgs: Record<string, string> = {}
  ) => {
    if (!navigator.onLine) {
      toast.error(t("error__network_error"));
      return;
    }
    if (errors instanceof InvalidEntityFields) {
      setErrors(errors.detail);
      return;
    }
    if (errors instanceof ConflictException) {
      setErrors([
        {
          loc: ["body", "name"],
          msg: t(
            "error__type with this name exists, please choose another name"
          ),
          type: "conflict",
        },
      ]);
      return;
    }
    if (errors instanceof EntityNotFoundException) {
      toast.error(t("message__type is not found", msgArgs));
    }

    toastUnexpectedError &&
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    returnToList &&
      delayCallback(() => navContext(EPRIVATEROUTE.TYPES), FORM_TOAST_TIMEOUT);
  };

  const createType = async (formData: IType) => {
    setLoading(true);
    try {
      await repoType.create(formData);
      navContext(EPRIVATEROUTE.TYPES);
    } catch (errors) {
      processServerError(errors);
    } finally {
      setLoading(false);
    }
  };
  const getTypeById = async (id: string) => {
    setFetchLoading(true);
    try {
      const { data: type } = await repoType.getById(id, [
        TYPE_NESTED_FIELD.VALIDATOR,
      ]);
      setModel(type);
    } catch (errors) {
      processServerError(errors, true, true, { id: id });
    } finally {
      setFetchLoading(false);
    }
  };

  const editType = async (formValues: IType) => {
    setLoading(true);
    const newData = { ...model, ...formValues };
    try {
      await repoType.update(newData);
      navContext(EPRIVATEROUTE.TYPES);
    } catch (errors) {
      processServerError(errors, true, true, { id: params.type_id! });
    } finally {
      setLoading(false);
    }
  };

  const getValidator = async (
    validatorId: string
  ): Promise<IValidator | null> => {
    try {
      const { data: validator } = await repoValidator.getById(validatorId);
      return validator;
    } catch (error) {
      if (error instanceof EntityNotFoundException) {
        setErrors([
          {
            loc: ["body", "validator_id"],
            msg: "validator is not found",
            type: "validation",
          },
        ]);
        return null;
      }
      processServerError(error, true);
    }
    return null;
  };

  const onSubmit = async (formValues: ITypeFormData) => {
    const formData = {
      ...formValues,
      validator_param_values: JSON.parse(formValues.validator_param_values),
      project_id: selectedProject.id,
    };
    // fetch validator
    const validator = await getValidator(formData.validator_id);
    if (validator == null) {
      return;
    }
    const validate = ajv.compile(validator.query_parameters_schema);
    const isValidParamValues = validate(formData.validator_param_values);

    if (!isValidParamValues) {
      setErrors([
        {
          loc: ["body", "validator_param_values"],
          msg: ajv.errorsText(validate.errors),
          type: "validation",
        },
      ]);
      return;
    }
    editMode ? editType(formData) : createType(formData);
  };
  return (
    <Fragment>
      <Helmet>
        <title>{t("create")}</title>
      </Helmet>
      <ToastContainer autoClose={FORM_TOAST_TIMEOUT} />
      {fetchLoading ? (
        <Loading />
      ) : (
        <div className="create-type-wrapper">
          <Grid container spacing={3} data-testid="create-type-wrapper">
            <Grid item xs={12} md={6}>
              <SLTypeForm
                editMode={editMode}
                errorList={errors}
                loading={loading}
                repoValidator={repoValidator}
                onSubmit={onSubmit}
                model={model}
              />
            </Grid>
            <InformationalSection />
          </Grid>
        </div>
      )}
    </Fragment>
  );
};
