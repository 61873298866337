import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ISelect } from "@src/components/type/select-type";
interface IProps {
  options: Array<ISelect>;
  value: string;
  onChange: (data: string) => void;
  name?: string;
  label: string;
}
export const CustomSelect: React.FC<IProps> = ({
  options,
  value,
  onChange,
  name,
  label,
}) => {
  const [select, setSelect] = useState<string>("");
  useEffect(() => {
    setSelect(value);
  }, [value]);
  const handleChange = (event: string) => {
    onChange(event);
  };

  return (
    <FormControl fullWidth data-testid="simple-select-component">
      <InputLabel id="simple-select-label" data-testid="simple-select-label">
        {label}
      </InputLabel>
      <Select
        fullWidth
        labelId="simple-select-label"
        id={label}
        value={select!}
        label={label}
        name={name}
        data-testid="simple-select-input"
        onChange={(e: SelectChangeEvent) => handleChange(e.target.value!)}
      >
        {options.map((option: ISelect, index: number) => (
          <MenuItem key={`${label}_${index}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
