import { IType, ITypeFilter } from "@src/models/type.model";
import { IResult, Repository } from "@src/services/repository";
import qs from "qs";
export enum TYPE_NESTED_FIELD {
  VALIDATOR = "validator",
}
export class TypeRepository extends Repository<
  IType,
  IType,
  IType,
  IType,
  ITypeFilter
> {
  constructor() {
    super("/v1.0/types");
  }
  // add test for this paer
  async getById(
    id: string,
    nested_fields?: Array<TYPE_NESTED_FIELD> | null
  ): Promise<IResult<IType>> {
    const url = `/v1.0/types/${id}?${qs.stringify(
      { nested_fields },
      {
        indices: false,
      }
    )}`;
    const rtn = await this.http_client.get<IResult<IType>>(url, this.headers);
    this.raiseExceptionOnError(rtn);
    return rtn.body;
  }
}
